import { PaymentSummary, LineItem, MethodData, APIRequest } from '../Types';
import { Customer } from '../Customer';
import { MerchantWarriorController } from '../MerchantWarriorController';
import { DigitalWallet } from './DigitalWallet';

declare global {
    interface Window {
        ApplePaySession: ApplePaySession,
    }
}

export class ApplePayIframe2 implements DigitalWallet {
    private summary: PaymentSummary;
    private controller: MerchantWarriorController;
    constructor(controller: MerchantWarriorController,summary: PaymentSummary) {
        this.summary = summary;
        this.controller = controller;
    }

    public static create(controller: MerchantWarriorController, summary: PaymentSummary): Promise<ApplePayIframe2> {
        const wallet = new ApplePayIframe2(controller,summary);
        return Promise.resolve(wallet);
    }


    public getPayButton(): Promise<HTMLElement> {
        const classes: string[] = [];
        const children: HTMLElement[] = [];
        classes.push('apple-pay-button-with-text');

        if (this.summary.style == 'light') {
            classes.push('apple-pay-button-white-with-text');
        } else { // Default dark if not set
            classes.push('apple-pay-button-black-with-text');
        }

        switch (this.summary.applePay?.buttonText) {
            case "buy":
                classes.push('apple-pay-button-buy');
                break;
            case "donate":
                classes.push('apple-pay-button-donate');
                break;
            case "plain":
                classes.push('apple-pay-button-plain');
                break;
            case "set-up":
                classes.push('apple-pay-button-set-up');
                break;
            case "book":
                classes.push('apple-pay-button-book');
                break;
            case "check-out":
                classes.push('apple-pay-button-check-out');
                break;
            case "subscribe":
                classes.push('apple-pay-button-subscribe');
                break;
            case "add-money":
                classes.push('apple-pay-button-add-money');
                break;
            case "contribute":
                classes.push('apple-pay-button-contribute');
                break;
            case "order":
                classes.push('apple-pay-button-order');
                break;
            case "reload":
                classes.push('apple-pay-button-reload');
                break;
            case "rent":
                classes.push('apple-pay-button-rent');
                break;
            case "support":
                classes.push('apple-pay-button-support');
                break;
            case "tip":
                classes.push('apple-pay-button-tip');
                break;
            case "top-up":
                classes.push('apple-pay-button-top-up');
                break;
            case "continue":
                classes.push('apple-pay-button-continue');
                break;
            default:
                classes.push('apple-pay-button-buy');
                break;
        }

        const text: HTMLElement = document.createElement('span');
        children.push(text);

        const logo: HTMLElement = document.createElement('span');
        children.push(logo);
        return this.getButton(classes, children).then((button: HTMLElement) => {
            button.onclick = () => { 
                this.startPayment();
            };

            return button;
        });
    }

    public getAddButton(): Promise<HTMLElement> {
        const classes: string[] = [];
        const children: HTMLElement[] = [];
        classes.push('apple-pay-button');

        if (this.summary.style == 'light') {
            classes.push('apple-pay-button-white');
        } else { // Default dark if not set
            classes.push('apple-pay-button-black');
        }

        return this.getButton(classes, children).then((button: HTMLElement) => {
            button.onclick = () => { 
                this.startAddCard();
            };

            return button;
        });
    }

    private getButton(classes: string[], children: HTMLElement[] = []): Promise<HTMLElement> {
        const button: HTMLElement = document.createElement('div');
        button.style.width = '100%'; // Why doesn't this work from CSS directly? Beats me
        button.style.height = '45px';
        const margin: string = '5px';

        for (let i = 0; i < classes.length; i++) {
            const cssClass = classes[i];
            button.classList.add(cssClass);
        }

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            button.appendChild(child);
        }

        //Integrate with ApplepayIframe introduced Style Feature 
        if(this.summary?.applePay && !(this.summary?.applePay.builtFrom == 'iframe')){
            if(this.summary.applePay.buttonHeight) button.style.height = this.summary.applePay.buttonHeight;
            if(this.summary.applePay.buttonWidth) button.style.width = this.summary.applePay.buttonWidth;
            if(this.summary.applePay.marginTop) button.style.marginTop = this.summary.applePay.marginTop;
            if(this.summary.applePay.marginRight) button.style.marginRight = this.summary.applePay.marginRight;
            if(this.summary.applePay.marginBottom) button.style.marginBottom = this.summary.applePay.marginBottom;
            if(this.summary.applePay.marginLeft) button.style.marginLeft = this.summary.applePay.marginLeft;
        }

        return Promise.resolve(button);
    }

    private startPayment(): void {
        if (window.self !== window.top) {
            var msg = {
                'message':'mw_applepay_request',
                'action':'pay',
            };
            //Send Messager
            window.parent.postMessage(JSON.stringify(msg), "*");
            let self = this;
            let handleMessageEvent_applepay_iframePay = function(event:MessageEvent) {         
                let result
                try {
                    result = JSON.parse(event.data);
                } catch (e){return;}

                if(result.message == "mw_applepay_response" && result.action == "payment-complete"){
                    self.controller.emit('payment-complete', result.status, result.response);
                    MerchantWarriorController.stopEventPropagation(event);
                    window.removeEventListener("message", handleMessageEvent_applepay_iframePay);
                }
            }
            window.addEventListener("message", handleMessageEvent_applepay_iframePay);
        }
    }

    private startAddCard(): void {
        if (window.self !== window.top) {
            var msg = {
                'message':'mw_applepay_request',
                'action':'addCard',
            };
            window.parent.postMessage(JSON.stringify(msg), "*");
            let self = this;
            let handleMessageEvent_applepay_iframeAddCard = function(event:MessageEvent) {         
                let result
                try {
                    result = JSON.parse(event.data);
                } catch (e){return;}
                if(result.message == "mw_applepay_response" && result.action == "card-added"){
                    self.controller.emit('card-added', result.status, result.response);
                    MerchantWarriorController.stopEventPropagation(event);
                    window.removeEventListener("message", handleMessageEvent_applepay_iframeAddCard);
                }
            }
            window.addEventListener("message", handleMessageEvent_applepay_iframeAddCard);
        }
    }

    //Placeholder designed for adapting to the object interface only.
    public getMethodData(): Promise<MethodData> {
        const methodData = {
            supportedMethods:'',
            data: ''
        };

        return Promise.resolve(methodData);
    }

}
