import { Customer, RequestCustomer } from './Customer';
import { PaymentSummary, MethodData, MerchantWarriorOptions, MerchantWarriorDefaultDevOptions, MerchantWarriorDefaultProdOptions, MerchantInfo } from './Types';

export class Util {
    public static formatCustomer(customer: Customer): RequestCustomer {
        const formatted: RequestCustomer = {
            customerName: customer.name,
            customerCountry: customer.country,
            customerState: customer.state,
            customerCity: customer.city,
            customerAddress: customer.address,
            customerPostCode: customer.postCode,
            customerPhone: customer?.phone,
            customerEmail: customer?.email,
            customerIP: customer?.ip,
        }
        
        return formatted;
    }

    public static formatPaymentSummary(summary: PaymentSummary,merchantInfo: MerchantInfo): PaymentDetailsInit {
        
        let totalPrice: string = '';
        if (typeof summary.total.amount == 'string') {
            totalPrice = summary.total.amount;
        } else {
            totalPrice = summary.total.amount.value;
        }

        let currency = merchantInfo.currency;
        if(typeof summary.transaction?.currency == "string")currency = summary.transaction?.currency;
        if(typeof summary.transaction?.transactionCurrency == "string")currency = summary.transaction?.transactionCurrency;


        return {
            total: {
                amount: {
                    value: totalPrice,
                    currency: currency
                },
                label: summary.total.label,
                pending: false
            }
        }
    }

    public static fillOptions(options: Partial<MerchantWarriorOptions>): MerchantWarriorOptions {
        if (!options) {
            options = {};
        }
        // Populate optional values with defaults
        let defaultOptions: MerchantWarriorOptions;
        switch (options?.environment) {
            case 'development':
                defaultOptions = MerchantWarriorDefaultDevOptions;
                break;
            case 'production':
            default:
                defaultOptions = MerchantWarriorDefaultProdOptions;
                break;
        }
        const fullOptions: MerchantWarriorOptions = Object.assign({}, defaultOptions, options);

        return fullOptions;
    }

}
