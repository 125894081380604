import { MerchantWarriorController } from './MerchantWarriorController';
import { MerchantInfo, MerchantInfoResponse, MerchantWarriorOptions, PaymentSummary } from './Types';
import { Customer, RequestCustomer } from './Customer';
import { Element } from './Element';
import { DigitalWalletWrapper } from './Wallets/DigitalWalletWrapper';
import { Util } from './Util';
import { ApplePay } from './Wallets/ApplePay';

declare global {
    interface Window {
        MerchantWarriorController: typeof MerchantWarriorController,
    }
}
window.MerchantWarriorController = MerchantWarriorController;
export class MerchantWarrior {
    public static getVersion(): string {
        const environment = process.env.ENVIRONMENT ?? '';
        const version = process.env.VERSION ?? '';
        const containerVersion = environment + '-' + version;
        const controllerVersion = window.MerchantWarriorController.getVersion();
        return controllerVersion + '(' + containerVersion + ')';
    }

    public static create(uuid: string, apiKey: string, options: MerchantWarriorOptions): Promise<MerchantWarriorController> {
        options = Util.fillOptions(options);
        return new Promise<boolean>((resolve: any, reject: any) => {
            const fallback = (process.env.MW_FALLBACK ?? 'FALSE') == 'TRUE';
//MW_FALLBACK_URL=https://will-secure.merchantwarrior.test/sdk

            if (options.environment == 'development' && fallback) {
                const fallbackPromise = MerchantWarrior.loadFallback(options.version);
                resolve(fallbackPromise);
            }

            resolve();
        }).then(() => {
            return MerchantWarrior.getMerchantInfo(uuid, options.fuse, options.productName)
        }).then((info: MerchantInfo) => {
            const controller = new window.MerchantWarriorController(uuid, apiKey, options, info);
            return controller;
        });
    }

    public static createWithToken(accessToken: string, options: MerchantWarriorOptions): Promise<MerchantWarriorController> {
        options = Util.fillOptions(options);
        return new Promise<boolean>((resolve: any, reject: any) => {
            const fallback = (process.env.MW_FALLBACK ?? 'FALSE') == 'TRUE';
//MW_FALLBACK_URL=https://will-secure.merchantwarrior.test/sdk

            if (options.environment == 'development' && fallback) {
                const fallbackPromise = MerchantWarrior.loadFallback(options.version);
                resolve(fallbackPromise);
            }

            resolve();
        }).then(() => {
            return MerchantWarrior.getMerchantInfo(accessToken, options.fuse)  
        }).then((info: MerchantInfo) => {
            const controller = new window.MerchantWarriorController(accessToken, '', options, info);
            return controller;
        });
    }

    public static createSync(uuid: string, apiKey: string, options: MerchantWarriorOptions, info: MerchantInfo): MerchantWarriorController {
        return new window.MerchantWarriorController(uuid, apiKey, options, info);
    }

    public static createSyncWithToken(accessToken: string, options: MerchantWarriorOptions, info: MerchantInfo): MerchantWarriorController {
        return new window.MerchantWarriorController(accessToken, '', options, info);
    }

    public static getMerchantInfo(uuid: string, fuse?: string, productName?: string): Promise<MerchantInfo> {
        if (!fuse) {
            fuse = process.env.MW_FUSE_URL;
        }
        if(productName){
            var infoURL = fuse + 'custom/m/' + uuid +'/' + productName + '/info.json';
        }else{
            var infoURL = fuse + 'custom/m/' + uuid + '/info.json';
        }


        return fetch(infoURL).then((response: Response) => {
            return response.json();
        }).then((json: MerchantInfoResponse) => {
            if (!json.status) {
                throw new Error('Could not find merchant information');
            }

            return json.info;
        })
    }

    private static loadFallback(version: string): Promise<void> {
        return new Promise<void>((resolve: any, reject: any) => {
            const script = document.createElement('script');
            let src = process.env.MW_FALLBACK_URL ?? '';
            if (src.length == 0) {
                reject('Configuration error');
            }

            src += 'merchantwarrior.' + version + '-controller.min.js';

            script.src = src;
            let hold = window.MerchantWarriorController.getVersion();
            script.onload = () => {
                resolve();
            }

            document.head.appendChild(script);
        });
    }
}


//Parent Loaded, Parent Payframe Loaded, Child Iframe Loaded, Child Payframe Loaded, for testing - Child ApplePay Iframe Loaded
let applepayManager : ApplePay;
var thePresentPaymentSummary : PaymentSummary;

const handleApplepayMessageEnvent = (event:MessageEvent) => {
    if (window.self === window.top) {
        if (hasPostMessageMethod(event.source)) {
            let result;
            try {
                result = JSON.parse(event.data);
            } catch (e){return;}
            if(result.message && result.message == "mw_applepay_request" && result.action){
                if(result.action == "initiate"){
                    let summary = result.summary;
                    summary.wallets = ['applepay'];
                    if(summary.applePay){
                        summary.applePay.iframe = false;
                    }else{
                        summary.applePay = {};
                    }
                    summary.applePay.builtFrom = "iframe";

                    //Allow the merchant to rebuild the Apple Pay iFrame as long as their customers canMakePayments

                    // if(applepayManager !== undefined){
                        // if(thePresentPaymentSummary !==undefined && areObjectsEqual(summary,thePresentPaymentSummary)){

                        //     if (hasPostMessageMethod(event.source)) {
                        //         event.source.postMessage(JSON.stringify({
                        //             'message': "mw_applepay_response",
                        //             'action': "duplicated"
                        //         }),event.origin);
                        //     }
                        //     MerchantWarriorController.stopEventPropagation(event);
                        //     return;
                        // }
                    // }

                    let mwarriorController:MerchantWarriorController;
                    if(typeof (result.accessToken) === 'string' && result.accessToken.length > 0) {
                        if (result.options.autoProcess === undefined || result.options.autoProcess === null) {
                            result.options.autoProcess = true; 
                            // Merchant doen't require to set-up middleware in the TLD (window.top)
                            // Unless autoProcess is explicitly boolean false
                        }
                        mwarriorController = MerchantWarrior.createSyncWithToken(result.accessToken, result.options, result.merchantInfo);
                    } else{
                        mwarriorController = MerchantWarrior.createSync(result.merchantUUID,result.apiKey, result.options, result.merchantInfo);
                    }
                    applepayManager = new ApplePay(mwarriorController, summary);

                    if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
                        thePresentPaymentSummary = summary;
                        if (hasPostMessageMethod(event.source)) {
                            event.source.postMessage(JSON.stringify({
                                'message': "mw_applepay_response",
                                'action': "acceptedApplepay"
                            }),event.origin);
                        }
                    }else{
                        if (hasPostMessageMethod(event.source)) {
                            event.source.postMessage(JSON.stringify({
                                'message': "mw_applepay_response",
                                'action': "cannotMakePayments"
                            }),event.origin);
                        }
                        MerchantWarriorController.stopEventPropagation(event);
                        return;
                    }
             
                    mwarriorController.on('payment-complete', (status:any, response:any)=>{
                        var responseData;
                        if(response && response !== "string"){
                            responseData = JSON.stringify({
                                'message': "mw_applepay_response",
                                'action': "payment-complete",
                                'status': status,
                                'response': response,
                            });
                            if (hasPostMessageMethod(event.source)) event.source.postMessage(responseData,event.origin);
                        }
                    });
                    
                
                    mwarriorController.on('card-added', (status:any, response:any) => {
                        var responseData;
                        if(response && response !== "string"){
                            responseData = JSON.stringify({
                                'message': "mw_applepay_response",
                                'action': "card-added",
                                'status': status,
                                'response': response,
                            });
                            // window.parent.postMessage(responseData, '*');
                            if (hasPostMessageMethod(event.source)) event.source.postMessage(responseData,event.origin);
                        }
                    });
                   
                    
                }else if(result.action == "pay"){
                    if(applepayManager)applepayManager.startPayment();
                }else if(result.action == "addCard"){
                    if(applepayManager)applepayManager.startAddCard();
                }


                MerchantWarriorController.stopEventPropagation(event);
            }
        }
    }
    return;
}

//custom type predicate hasPostMessageMethod 
function hasPostMessageMethod(obj: any): obj is { postMessage: ((message: any, targetOrigin: string, transfer?: Transferable[] | undefined) => void) } {
    return typeof obj?.postMessage === 'function';
}
function areObjectsEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}
if (window.self === window.top) {
    // console.log("Register Applepay Message Envent")
    window?.addEventListener('message',  (event) => handleApplepayMessageEnvent(event));
}