import { EventManager } from 'lipwig-events';

export class Element extends EventManager {
    private element: HTMLElement;
    constructor() {
        super();
        this.element = document.createElement('div');
        this.element.style.marginBottom = '5px';
    }

    public add(element: HTMLElement): void { //TODO: Promise?
        this.element.appendChild(element);
    }

    public mount(target: string | HTMLElement): Promise<Element> {
        let container: HTMLElement | null;
        if (typeof target === 'string') {
            let selector: string;
            switch (target[0]) {
                case '#':
                    selector = target.substring(1);
                    break;
                case '.':
                default:
                    selector = target;
                    break;
            }

            container = document.getElementById(selector);
        } else {
            container = target;
        }

        if (!container) {
            return Promise.reject();
        }

        container.appendChild(this.element);

        return Promise.resolve(this);
    }
}
