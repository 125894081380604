import { PaymentSummary, LineItem, MethodData, APIRequest } from '../Types';
import { MerchantWarriorController } from '../MerchantWarriorController';
import { DigitalWallet } from './DigitalWallet';
import {Customer} from "../Customer";

declare global {
    interface Window {
        ApplePaySession: ApplePaySession,
    }
}


export class ApplePayIframe implements DigitalWallet {
    private controller: MerchantWarriorController;
    private data: any;
    private summary: PaymentSummary;
    private supportedMethods: string;

    constructor(controller: MerchantWarriorController, summary: PaymentSummary) {
        this.supportedMethods = 'https://apple.com/apple-pay'; // For PR API
        this.data = {
            version: 3,
            merchantIdentifier: process.env.MW_APPLE_MERCHANT_IDENTIFIER ?? '',
            merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
            supportedNetworks: ['visa', 'mastercard'],
            countryCode: controller.getInfo().country
        };
        this.controller = controller;
        this.summary = summary;

        this.processPayment();

    }

    public static create(controller: MerchantWarriorController, summary: PaymentSummary): Promise<ApplePayIframe> {

        if (!window.ApplePaySession) {
            return Promise.reject();
        }

        if (!ApplePaySession.canMakePayments()) {
            return Promise.reject();
        }

        const wallet : ApplePayIframe = new ApplePayIframe(controller, summary);

        return Promise.resolve(wallet);

    }

    /**
     * Get the iframe
     * @param pay
     * @private
     */
    private getIframe(pay: boolean = true): Promise<HTMLElement> {
        // Create Iframe
        const iframe: HTMLIFrameElement = document.createElement('iframe');

        const uuid : string =  this.controller.getMerchantUUID();
        const apiKey : string =  this.controller.getMerchantApiKey();
        const accessToken : string =  this.controller.getMerchantAccessToken();

        if(accessToken && accessToken.length > 0){
            iframe.src = process.env.MW_IFRAME_URL+'applepay-iframe?token='+accessToken;
        }else{
            iframe.src = process.env.MW_IFRAME_URL+'applepay-iframe?uuid='+uuid+'&key='+apiKey;
        }

        iframe.style.border = '0px';
        iframe.style.padding = '0';
        iframe.style.margin = '0px';
        iframe.allow = "payment";

        const iframeLoaded : Promise<unknown> = new Promise((resolve, reject) => {
            iframe.onload = resolve;
            iframe.onerror = reject;
        });
        iframeLoaded.then( () => {
            const iframeData = {
                data: this.summary,
                styles: {
                    height: this.summary?.applePay?.buttonHeight,
                    width: this.summary?.applePay?.buttonWidth,
                }
            }

            // pass styles from summary (Moved below style into Applepay Object)
            iframe.style.height = this.summary?.applePay?.buttonHeight ? this.summary.applePay.buttonHeight : '';
            iframe.style.width = this.summary?.applePay?.buttonWidth ? this.summary.applePay.buttonWidth : '100%';
            iframe.style.marginTop = this.summary?.applePay?.marginTop ? this.summary.applePay.marginTop : '';
            iframe.style.marginRight = this.summary?.applePay?.marginRight ? this.summary.applePay.marginRight : '';
            iframe.style.marginBottom = this.summary?.applePay?.marginBottom ? this.summary.applePay.marginBottom : '';
            iframe.style.marginLeft = this.summary?.applePay?.marginLeft ? this.summary.applePay.marginLeft : '';

            this.passPostMessage(iframe, iframeData);
        }).catch(error => {
            return Promise.reject();
        });

        return Promise.resolve(iframe);
    }

    /**
     * Send the message to the iframe window
     * @param iframe
     * @param iframeData
     * @private
     */
    private passPostMessage(iframe: HTMLIFrameElement, iframeData: object) {
        const message : string = JSON.stringify(iframeData);
        iframe.contentWindow?.postMessage(message, '*');
    }

    public getPayButton(): Promise<HTMLElement> {
        return this.getIframe();
    }

    public getAddButton(): Promise<HTMLElement> {
        return this.getIframe(false);
    }


    public getMethodData(): Promise<MethodData> {
        const methodData = {
            supportedMethods: this.supportedMethods,
            data: this.data
        };

        return Promise.resolve(methodData);
    }

    public processPayment() {
        window?.addEventListener('message',  (event) => {
            if(event.origin && process.env.MW_FUSE_URL){
                let originUrl : URL = new URL(event.origin);
                let fuseUrl : URL = new URL(process.env.MW_FUSE_URL);

                if (originUrl.host !== fuseUrl.host) {
                    return;
                }

                // This gets triggered with the Apple controller emit function
                let result = JSON.parse(event.data);

                let status: boolean = false;
                if (result.responseCode == 0) {
                    status = true;
                }
                if(result.eventType == "payment-complete"){
                    this.controller.emit('payment-complete', status, result);
                }else{
                    this.controller.emit('card-added', status, result);
                }
                MerchantWarriorController.stopEventPropagation(event);
            }
            return;
        });
    }

}
