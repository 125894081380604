import { Util } from '../Util';
import { MethodData, PaymentSummary, APIRequest } from '../Types';
import { Customer } from '../Customer';
import { BasicCard } from './BasicCard';
import { ApplePay } from './ApplePay';
import { Element } from '../Element';
import { DigitalWallet } from './DigitalWallet';
import { MerchantWarriorController } from '../MerchantWarriorController';
import { ApplePayIframe2 } from './ApplePayIframe2';

export class ConsolidatedWallet implements DigitalWallet {
    private methodData: MethodData[];
    private controller: MerchantWarriorController;
    private summary: PaymentSummary;
    constructor(controller: MerchantWarriorController, summary: PaymentSummary, methodData: MethodData[]) {
        this.controller = controller;
        this.summary = summary;
        this.methodData = methodData;
    }

    public static create(controller: MerchantWarriorController, summary: PaymentSummary, wallets: DigitalWallet[]): Promise<ConsolidatedWallet | ApplePay | ApplePayIframe2> {
        if (window.ApplePaySession) {
            // If Apple Pay is available, no other method will be
            let appleWanted = false;
            for (let i = 0; i < wallets.length; i++) {
                if (wallets[i] instanceof ApplePay) {
                    appleWanted = true;
                    break;
                }
            }
            if (appleWanted) {
                return ApplePay.create(controller, summary);
            } else {
                return Promise.reject('No payment type available');
            }
        }

        const methodDataPromises: Promise<MethodData>[] = [];
        for (let i = 0; i < wallets.length; i++) {
            const wallet: DigitalWallet = wallets[i];
            const promise = wallet.getMethodData();
            methodDataPromises.push(promise);
        }

        return Promise.allSettled(methodDataPromises).then((results: PromiseSettledResult<MethodData>[]) => {
            const methodData: MethodData[] = [];
            for (let i = 0; i < results.length; i++) {
                const result: PromiseSettledResult<MethodData> = results[i];
                if (result.status == 'fulfilled') {
                    methodData.push(result.value);
                }
            }

            if (methodData.length == 0) {
                throw new Error('No payment type available');
            }

            const button = document.createElement('input');
            button.type = 'button';
            button.value = 'Pay with Browser';
            button.style.height = '45px';
            button.style.width = '100%';
            
            const wallet = new ConsolidatedWallet(controller, summary, methodData);
            return wallet.canMakePayment().then((available: boolean) => {
                if (!available) {
                    throw new Error('No payment type available');
                }

                return wallet;
            });
        });
    }

    private buildPaymentRequest(details: PaymentDetailsInit): PaymentRequest {
        return new PaymentRequest(this.methodData, details);
    }

    private canMakePayment(): Promise<boolean> {
        const details = Util.formatPaymentSummary(this.summary, this.controller.getInfo());
        const request = this.buildPaymentRequest(details);
        return request.canMakePayment();
    }

    private startPayment(): void {
        const details = Util.formatPaymentSummary(this.summary, this.controller.getInfo());
        const request = this.buildPaymentRequest(details);
        let outerResponse: PaymentResponse | undefined;
        new Promise<Customer>((resolve: any, reject: any) => {
            if (this.summary.customer) {
                resolve(this.summary.customer);
                return;
            }
            this.controller.emit('request-customer', resolve, reject); // Prompt MerchantWarriorController object for customer
        }).then((customer: Customer) => {
            this.summary.customer = customer;
            return request.show();
        }).then((response: PaymentResponse) => {
            outerResponse = response;
            const apiRequest: APIRequest = {
                method: 'processCard',
                transactionAmount: details.total.amount.value,
                transactionCurrency: details.total.amount.currency,
                transactionProduct: details.total.label
            }

            if(this.summary.transaction){
                if(this.summary.transaction.transactionReferenceID) apiRequest.transactionReferenceID = this.summary.transaction.transactionReferenceID;
                if(this.summary.transaction.storeID) apiRequest.storeID = this.summary.transaction.storeID;
                if(this.summary.transaction.custom1) apiRequest.custom1 = this.summary.transaction.custom1;
                if(this.summary.transaction.custom2) apiRequest.custom2 = this.summary.transaction.custom2;
                if(this.summary.transaction.custom3) apiRequest.custom3 = this.summary.transaction.custom3;
                if(this.summary.transaction.transactionProduct) apiRequest.transactionProduct = this.summary.transaction.transactionProduct;
            }

            if (this.summary.addCard) {
                apiRequest.addCard = 1;
            }

            switch (response.methodName) {
                case 'basic-card':
                    const expiry: string = response.details.expiryMonth + response.details.expiryYear.slice(2);
                    apiRequest.paymentCardNumber = response.details.cardNumber;
                    apiRequest.paymentCardExpiry = expiry;
                    apiRequest.paymentCardName = response.details.cardholderName;
                    apiRequest.paymentCardCSC = response.details.cardSecurityCode;
                    break;
                case 'https://google.com/pay':
                    apiRequest.digitalWalletToken = response.details.paymentMethodData.tokenizationData.token;
                    break;
                //case 'https:
                default:
                    // ???
                    throw new Error();
                    break;
            }
            
            return this.controller.makeRequest(apiRequest, this.summary.customer);
            //this.handlePayment(response);
        }).then((result: any) => {
            let status: boolean = false;
            if (result.responseCode == 0) {
                status = true;
            }
            this.controller.emit('payment-complete', status, result);
            if (outerResponse) {
                outerResponse.complete();
            }
        }).catch((error: any) => {
            this.controller.emit('payment-complete', false, null, error);
            if (outerResponse) {
                outerResponse.complete();
            }
        });
    }

    private startAddCard(): void {
        const details = Util.formatPaymentSummary(this.summary, this.controller.getInfo());
        const request = this.buildPaymentRequest(details);
        let outerResponse: PaymentResponse | undefined;
        request.show().then((response: PaymentResponse) => {
            outerResponse = response;
            const apiRequest: APIRequest = {
                method: 'addCard',
            }

            switch (response.methodName) {
                case 'basic-card':
                    const expiry: string = response.details.expiryMonth + response.details.expiryYear.slice(2);
                    apiRequest.paymentCardNumber = response.details.cardNumber;
                    apiRequest.paymentCardExpiry = expiry;
                    apiRequest.paymentCardName = response.details.cardholderName;
                    apiRequest.paymentCardCSC = response.details.cardSecurityCode;
                    break;
                case 'https://google.com/pay':
                    apiRequest.digitalWalletToken = response.details.paymentMethodData.tokenizationData.token;
                    break;
                //case 'https:
                default:
                    // ???
                    throw new Error();
                    break;
            }
            
            return this.controller.makeRequest(apiRequest, this.summary.customer);
            //this.handlePayment(response);
        }).then((result: any) => {
            let status: boolean = false;
            if (result.responseCode == 0) {
                status = true;
            }
            this.controller.emit('card-added', status, result);
            if (outerResponse) {
                outerResponse.complete();
            }
        }).catch((error: any) => {
            this.controller.emit('card-added', false, null, error);
            if (outerResponse) {
                outerResponse.complete();
            }
        });

    }

    public getPayButton(): Promise<HTMLElement> {
        return this.getButton().then((button: HTMLElement) => {
            button.onclick = (evt: Event) => {
                this.startPayment();
            }

            return button;
        });
    }

    public getAddButton(): Promise<HTMLElement> {
        return this.getButton().then((button: HTMLElement) => {
            button.onclick = (evt: Event) => {
                this.startAddCard();
            }

            return button;
        });
    }

    private getButton(): Promise<HTMLElement> {
        const button = document.createElement('input');
        button.type = 'button';
        button.value = 'Pay with Browser';
        button.style.height = '45px';
        button.style.width = '100%';

        if (this.summary.style == 'light') {
            button.style.backgroundColor = '#FFFFFF';
            button.style.color = '#002B41';
        } else { // Default dark if not set
            button.style.backgroundColor = '#002B41';
            button.style.color = '#FFFFFF';
        }
        button.style.borderRadius = '4px';
        button.style.border = '1px solid transparent';
        button.style.fontSize = '16px';
        button.style.fontWeight = '400';

        return Promise.resolve(button);
    }

    public getMethodData(): Promise<MethodData> {
        return Promise.reject('No method data available');
    }
}
