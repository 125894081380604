import { Util } from '../Util';
import { Customer } from '../Customer';
import { PaymentSummary, MethodData, APIRequest } from '../Types';
import { MerchantWarriorController } from '../MerchantWarriorController';
import { DigitalWallet } from './DigitalWallet';

export class BasicCard implements DigitalWallet {
    private controller: MerchantWarriorController;
    private data: any;
    private summary: PaymentSummary;
    private supportedMethods: string;

    constructor(controller: MerchantWarriorController, summary: PaymentSummary) {
        this.supportedMethods = 'basic-card';
        this.data = {
            supportedNetworks: [
                'visa', 'mastercard'
            ]
        };

        this.controller = controller;
        this.summary = summary;

        this.buildPaymentRequest(); // This is to test if the payment request is buildable
    }

    public static create(controller: MerchantWarriorController, summary: PaymentSummary): Promise<BasicCard> {
        const wallet = new BasicCard(controller, summary);
        return wallet.canMakePayment().then((available: boolean) => {
            if (!available) {
                throw new Error();
            }

            return wallet;
        });
    }

    public canMakePayment(): Promise<boolean> {
        const request = this.buildPaymentRequest();
        return request.canMakePayment();
    }

    private buildPaymentRequest(): PaymentRequest {
        const methodData = {
            supportedMethods: this.supportedMethods,
            data: this.data
        }

        const details = this.buildPaymentDetails();

        return new PaymentRequest([methodData], details);
    }

    private buildPaymentDetails(): PaymentDetailsInit {
        return Util.formatPaymentSummary(this.summary, this.controller.getInfo());
        /*const details: PaymentDetailsInit = {
            total: {
                amount: {
                    currency: 'AUD',
                    value: '10.00'
                },
                label: 'It\'s the total!',
                pending: false
            }
        };

        return details;*/
    }

    private startPayment(): void {
        const request = this.buildPaymentRequest();
        let outerResponse: PaymentResponse | undefined;
        new Promise<Customer>((resolve: any, reject: any) => {
            if (this.summary.customer) {
                resolve(this.summary.customer);
                return;
            }
            this.controller.emit('request-customer', resolve, reject); // Prompt MerchantWarriorController object for customer
        }).then((customer: Customer) => {
            this.summary.customer = customer;
            return request.show();
        }).then((response: PaymentResponse) => {
            outerResponse = response;
            // These variables could use some renaming
            const paymentDetails: any = response.details;
            const purchaseDetails: PaymentDetailsInit = this.buildPaymentDetails();
            const expiry: string = paymentDetails.expiryMonth + paymentDetails.expiryYear.slice(2);
            const processCardRequest: APIRequest = {
                method: 'processCard',
                paymentCardNumber: paymentDetails.cardNumber,
                paymentCardExpiry: expiry,
                paymentCardName: paymentDetails.cardholderName,
                paymentCardCSC: paymentDetails.cardSecurityCode,

                transactionAmount: purchaseDetails.total.amount.value,
                transactionCurrency: purchaseDetails.total.amount.currency,
                transactionProduct: purchaseDetails.total.label
            }

            if(this.summary.transaction){
                if(this.summary.transaction.transactionReferenceID) processCardRequest.transactionReferenceID = this.summary.transaction.transactionReferenceID;
                if(this.summary.transaction.storeID) processCardRequest.storeID = this.summary.transaction.storeID;
                if(this.summary.transaction.custom1) processCardRequest.custom1 = this.summary.transaction.custom1;
                if(this.summary.transaction.custom2) processCardRequest.custom2 = this.summary.transaction.custom2;
                if(this.summary.transaction.custom3) processCardRequest.custom3 = this.summary.transaction.custom3;
                if(this.summary.transaction.transactionProduct) processCardRequest.transactionProduct = this.summary.transaction.transactionProduct;
            }

            if (this.summary.addCard) {
                processCardRequest.addCard = 1;
            }

            return this.controller.makeRequest(processCardRequest, this.summary.customer);
        }).then((result: any) => {
            let status: boolean = false;
            if (result.responseCode == 0) {
                status = true;
            }
            this.controller.emit('payment-complete', status, result);
            if (outerResponse) {
                outerResponse.complete();
            }
        }).catch((error: any) => {
            this.controller.emit('payment-complete', false, null, error);
            if (outerResponse) {
                outerResponse.complete();
            }
        });
    }

    public startAddCard(): void {
        const request = this.buildPaymentRequest();
        let outerResponse: PaymentResponse | undefined;
        request.show().then((response: PaymentResponse) => {
            outerResponse = response;
            // These variables could use some renaming
            const paymentDetails: any = response.details;
            const purchaseDetails: PaymentDetailsInit = this.buildPaymentDetails();
            const expiry: string = paymentDetails.expiryMonth + paymentDetails.expiryYear.slice(2);
            const processCardRequest: APIRequest = {
                method: 'addCard',
                paymentCardNumber: paymentDetails.cardNumber,
                paymentCardExpiry: expiry,
                paymentCardName: paymentDetails.cardholderName,
                paymentCardCSC: paymentDetails.cardSecurityCode,
            }

            return this.controller.makeRequest(processCardRequest, this.summary.customer);
        }).then((result: any) => {
            let status: boolean = false;
            if (result.responseCode == 0) {
                status = true;
            }
            this.controller.emit('card-added', status, result);
            if (outerResponse) {
                outerResponse.complete();
            }
        }).catch((error: any) => {
            this.controller.emit('card-added', false, null, error);
            if (outerResponse) {
                outerResponse.complete();
            }
        });
    }
    
    public getPayButton(): Promise<HTMLElement> {
        const light: boolean = this.summary.style == 'light';
        const style = {
            backgroundColor: light ? '#FFFFFF' : '#002B41',
            color: light ? '#002B41' : '#FFFFFF'
        }
        
        const text = 'Pay with Browser';
        return this.getButton(text, style).then((button: HTMLElement) => {
            button.onclick = (evt: Event) => {
                this.startPayment();
            }

            return button;
        });
    }

    public getAddButton(): Promise<HTMLElement> {
        const light: boolean = this.summary.style == 'light';
        const style = {
            backgroundColor: light ? '#FFFFFF' : '#002B41',
            color: light ? '#002B41' : '#FFFFFF'
        }
        
        const text = 'Add Browser Card';
        return this.getButton(text, style).then((button: HTMLElement) => {
            button.onclick = (evt: Event) => {
                this.startAddCard();
            }

            return button;
        });
    }

    private getButton(text: string, style: {[key: string]: string}): Promise<HTMLElement> {
        const button = document.createElement('input');
        button.type = 'button';
        button.value = text;
        button.style.height = '45px';
        button.style.width = '100%';
        button.style.borderRadius = '4px';
        button.style.border = '1px solid transparent';
        button.style.fontSize = '16px';
        button.style.fontWeight = '400';

        const attributes = Object.keys(style);
        for (let i = 0; i < attributes.length; i++) {
            const attribute: string = attributes[i];
            const value = style[attribute];
            (<any>button.style)[attribute] = value; // Cast because style is a sealed type
        }

        return Promise.resolve(button);
    }

    public getMethodData(): Promise<MethodData> {
        const methodData = {
            supportedMethods: this.supportedMethods,
            data: this.data
        };

        return Promise.resolve(methodData);
    }
}
