import { Customer } from '../Customer';
import { PaymentSummary, MethodData, APIRequest, threeDSPaymentInfo } from '../Types';
import { TDSManager } from './TDSManager';
import { TDS } from './TDS';


export class TDSv1 implements TDS {
    private threeDSPaymentInfo: threeDSPaymentInfo;
    private tdsManager: TDSManager;

    private data: any;
    private mwHandleNotifyURL = true;
    private threeDSNotifyURL: string;
    private tdsCardToken: any;
    public threeDSMethodFailure:boolean;
    public threeDSMethodFailureoverControl:any;

    constructor(tdsManager: TDSManager, threeDSPaymentInfo: threeDSPaymentInfo) {
        this.tdsManager = tdsManager;
        this.threeDSPaymentInfo = threeDSPaymentInfo;

        if(typeof this.threeDSPaymentInfo.threeDS.notifyURL != "string" ){
            // console.log("MW will handle the TDSv1 callbackURL, checkTDSAvailable in all mode");
            this.mwHandleNotifyURL = true;
            this.threeDSNotifyURL = this.tdsManager.getMWThreeDSNotifyURL();
        }else{
            this.mwHandleNotifyURL = false;
            this.threeDSNotifyURL = this.threeDSPaymentInfo.threeDS.notifyURL;
        }
        this.tdsCardToken = {
            tdsPayKey : "",
            tdsPayToken : ""
        };

        this.threeDSMethodFailure = false;
        this.threeDSMethodFailureoverControl = null;
    }



    checkEnrollment(paymentDetail:{}): string { 
        throw new Error('3DS1.0 checkEnrollment is conducted by TDSv2 automatically');
    }
    checkAuth(): Promise<HTMLElement> {
        throw new Error('3DS1.0 does not have checkAuth method');
    }
    checkPARes(paymentDetail : any): Promise<any> {
        const processCardRequest: APIRequest = {
            method: 'checkPARes',
            PARes: paymentDetail
        }
        const requestPromise = this.tdsManager.makeXHRRequest(processCardRequest);

        var checkPAResResponse = {
            responseCode: "",
            data: {}
        };


        return requestPromise.then(function(result:any){


            const responseCode = result.getElementsByTagName("responseCode");
            if(responseCode && responseCode.length > 0 && responseCode[0].childNodes.length > 0) {
                const responseCodeValue = result.getElementsByTagName("responseCode")[0].childNodes[0].nodeValue;
                if(responseCodeValue == '0'){
                    checkPAResResponse.responseCode = "0";
                    var liabilityShift = TDSManager.getXMLValue(result, "liabilityShift");
                    checkPAResResponse.data = {
                        threeDSEci: TDSManager.getXMLValue(result, "threeDSEci"),
                        threeDSCavv: TDSManager.getXMLValue(result, "threeDSCavv"),
                        threeDSToken: TDSManager.getXMLValue(result, "threeDSToken"),
                        threeDSXid:TDSManager.getXMLValue(result, "threeDSXid"),
                        threeDSStatus: TDSManager.getXMLValue(result, "threeDSResult"),  
                        liabilityShift :  (liabilityShift) ? liabilityShift : "N",
                    }
                }else{
                    checkPAResResponse.data =  TDSManager.formErrorResponse(result);
                }
            }else{
                //No responseCode
            }

            return checkPAResResponse;

        })   
    }


    public createThreeDSMethodIframe(requestDetail:any): boolean {
        
        var displayBox = document.getElementById(this.tdsManager.containerDivId); 
        var iframe = document.createElement('iframe');
        iframe.classList.add('tdsv1challengeIframe');
        iframe.name = "3dsv1challengeIframe";
        iframe.id = "3dsv1challengeIframe";
        iframe?.setAttribute('style',"height: 450px; width: 450px;");
        displayBox?.setAttribute('style',"height: 450px;");
        displayBox?.appendChild(iframe);
    
        var form = document.createElement("form");
        form.classList.add('hidden_TDS_From');
        form.id = "threeDSMethodForm";
        form.type = "hidden";
        displayBox?.appendChild(form);
    
        var input = document.createElement("input");
        input.id = "PaReq";
        input.name = "PaReq";
        input.type = "hidden";
        input.value = requestDetail.data.paReq;
        form.appendChild(input);
    
        var input = document.createElement("input");
        input.id = "TermUrl";
        input.name = "TermUrl";
        input.type = "hidden";
        input.value = this.threeDSNotifyURL;
        form.appendChild(input);
    
        form.action = requestDetail.data.acsURL;
        form.target = '3dsv1challengeIframe';
        form.method = 'post';
        form.submit();

        let self = this;
        iframe.onload = function(){
            if(typeof self.tdsManager.loaded == 'function') {
                self.tdsManager.loaded();
            }

            if(self.tdsManager.iFrameMode == "subFrame"){
                var msg = {
                    mwTDSResult: "TDSPayframeSubFrameAction",
                    requiredAction : "loaded"
                    } 
                    var jsnMsg = JSON.stringify(msg);
                self.tdsManager.sendMessage(jsnMsg)
            }
        }

        this.tdsManager._handleThreeDSV1MethodCallback = function(event){
            self.tdsManager.handleThreeDSMethodCallback(event, "TDSv1");
        }

        if(this.mwHandleNotifyURL){
            window?.addEventListener("message", this.tdsManager._handleThreeDSV1MethodCallback);
        
            this.threeDSMethodFailureoverControl = setTimeout(()=>{ 
                this.threeDSMethodFailure = true;
                this.tdsManager.returnMWCallBack({
                    "responseCode" : "-4",
                    "responseMessage" : "3DSv1 Challenge Flow Timeout",
                });
            }, 600000);

        }

        return true;
    }
    
}