import { PaymentSummary } from '../Types';

import { MerchantWarriorController } from '../MerchantWarriorController';
import { DigitalWallet } from './DigitalWallet';

import { ApplePay } from './ApplePay';
import { GooglePay } from './GooglePay';
import { BasicCard } from './BasicCard';
import { ApplePayIframe } from './ApplePayIframe';
import { GooglePayIframe } from './GooglePayIframe';

export class DigitalWalletWrapper {
    static WALLETS: {
        [key: string]: any
    } = {
        'applepay': ApplePay,
        'googlepay': GooglePay,
        'basic-card': BasicCard,
        'applepay-iframe' : ApplePayIframe,
        'googlepay-iframe' : GooglePayIframe
    };

    public static getWallets(controller: MerchantWarriorController, summary: PaymentSummary): Promise<DigitalWallet[]> {
        const walletList = Object.keys(DigitalWalletWrapper.WALLETS);
        if (!summary.wallets || summary.wallets.length == 0) {
            summary.wallets = walletList.filter( wallet => !wallet.match(/-iframe/));
        }

        let availableWallets = Object.keys(controller.getInfo().wallets);
        availableWallets.push('basic-card');

        const promises: Promise<DigitalWallet>[] = [];
        let options = controller.getOptions();

        for (let i = 0; i < summary.wallets.length; i++) {
            const walletName = summary.wallets[i];
            if (!availableWallets.includes(walletName)) {
                continue;
            }
            let wallet = DigitalWalletWrapper.WALLETS[walletName];

            if(walletName == "googlepay" &&  summary.googlePay?.iframe){
                wallet = DigitalWalletWrapper.WALLETS[walletName+"-iframe"];
            }else if(walletName == "applepay" && options.environment == 'development' &&  summary.applePay?.iframe){
                //ApplePay proactive iframe mode is supposed to be invoked only within the development environment.
                wallet = DigitalWalletWrapper.WALLETS[walletName+"-iframe"];
            }
            const promise = wallet.create(controller, summary);
            promises.push(promise);
        }


        return Promise.allSettled(promises).then((results: PromiseSettledResult<DigitalWallet>[]) => {
            const wallets: DigitalWallet[] = [];
            for (let i = 0; i < results.length; i++) {
                const result: PromiseSettledResult<DigitalWallet> = results[i];
                if (result.status == 'fulfilled') {
                    wallets.push(result.value);
                }
            }

            if (wallets.length == 0) {
                throw new Error('No payment type available');
            }

            return wallets;
        });
    }
}
