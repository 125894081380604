import { MethodData, PaymentSummary, APIRequest , threeDSPaymentInfo} from '../Types';
import { Util } from '../Util';
import { Customer, RequestCustomer } from '../Customer';

import { MerchantWarriorController } from '../MerchantWarriorController';

import { TDS } from './TDS';
import { TDSv2 } from './TDSv2';
import { TDSv1 } from './TDSv1';

export class TDSManager {

    private controller: MerchantWarriorController;

    private tdsV1: TDSv1;
    private tdsV2: TDSv2;
    private threeDSResult: any;
    private threeDSPaymentInfo: threeDSPaymentInfo;

    public challengeWidth: string;
    public challengeHeight: string;
    public iFrameMode: string;
    public mwCallback = function(result:any){};
    public threeDSFinishCallback = function(){}
	public loading = function loading(){};
    public loaded = function loaded(){};
    public containerDivId: string;

    public _handleChallengeCallback = function(event : any){};
    public _handleThreeDSMethodCallback = function(event: any){};
    public _handleThreeDSV1MethodCallback = function(event: any){};



    constructor(controller: MerchantWarriorController, threeDSPaymentInfo: threeDSPaymentInfo, containerDivId:string = "threeds-container") {
        //We could potentially respond with multiple result formats
        this.threeDSResult = {
            threeDSToken: undefined,
        }
        this.controller = controller;
        this.threeDSPaymentInfo = threeDSPaymentInfo;
        this.threeDSPaymentInfo = this.cleanThreeDSPaymentInfo(this.threeDSPaymentInfo);
        this.tdsV1 = new TDSv1(this, this.threeDSPaymentInfo);
        this.tdsV2 = new TDSv2(this, this.threeDSPaymentInfo);

        this.challengeWidth = "600px";
        this.challengeHeight = "400px";
        this.iFrameMode = "default";
        this.containerDivId = containerDivId;
    }


    public setChallengeIframeScale(width:string, height:string, iFrameMode:string = "default"){
        this.challengeWidth = width;
        this.challengeHeight = height;
        this.iFrameMode = iFrameMode;
    }


    

    //All in one mode; MW Handle the notifyURL
    public checkTDS(){
        this.tdsV2.checkEnrollment().then((result:any)=>{
            this.handleCheckEnrollmentResult(result);
        })
    }
    //Merchants need to handle the notifyURL 
    public checkEnrollment(){
        this.tdsV2.checkEnrollment().then((result:any)=>{
            this.handleCheckEnrollmentResult(result);
        })
    }

    public checkTDSAuth(threeDSServerTransID:string, threeDSCompInd:string = "Y"){
        this.tdsV2.checkAuth(threeDSServerTransID, threeDSCompInd).then((result:any) => {
            this.handleCheckTDSAuthResult(result);
        });
    }

    public checkPARes(cres:string){

        let PARes3DSv2:boolean = false;
        try {
            JSON.parse(window.atob(cres));
            PARes3DSv2 = true;
        } catch(e) {
        }

        if(PARes3DSv2){
            this.tdsV2.checkPARes(cres).then((result:any) => {
                this.handleCheckPAResResult(result);
            });
        }else{
            this.tdsV1.checkPARes(cres).then((result:any) => {
                this.returnMWCallBack(result.data);
            });
        }
    }
    

    public handleCheckEnrollmentResult(result:any){
        if(result.data.support) this.tdsV2.supportMode = result.data.support;

        if(result.requiredAction == "threeDSMethodIframe"){
            this.createIframe(result, "ThreeDSMethod", null);
        }else if(result.requiredAction == "checkTDSAuth"){
            this.tdsV2.checkAuth(result.data.threeDSServerTransID, result.data.threeDSCompInd).then((result:any) => {
                this.handleCheckTDSAuthResult(result);
            });
        }else if(result.requiredAction == "None"){
            //If there is no requred action, return callback
            this.returnMWCallBack(result.data);
        } 
    }

    // enable iframe fallback to 1.0 fingerprint method
    public createIframe(requestDetail:any, requiredAction:string, callbackFunction:any){

        if(requestDetail.version == "2.0"){
            if(requiredAction == "ThreeDSMethod"){
                this.tdsV2.createThreeDSMethodIframe(requestDetail);
            }
        }else if(requestDetail.version == "1.0"){
            if(requiredAction == "ThreeDSMethod"){
                this.tdsV1.createThreeDSMethodIframe(requestDetail);
            }
        }
    }

    public handleThreeDSMethodCallback(event:any, source: string){
        // console.log("Debug - handleThreeDSMethodCallback fallback clear" + this.tdsV2.threeDSMethodFailureoverControl);
        if(this.tdsV2.threeDSMethodFailureoverControl){clearTimeout(this.tdsV2.threeDSMethodFailureoverControl);this.tdsV2.threeDSMethodFailureoverControl = null;}
        if(this.tdsV1.threeDSMethodFailureoverControl){clearTimeout(this.tdsV1.threeDSMethodFailureoverControl);this.tdsV1.threeDSMethodFailureoverControl = null;}
        //it must came from the previous notifyURL (MW'S or merchant's) 
        if(((this.getHostName(event.origin) == this.getHostName(this.getMWThreeDSNotifyURL()))
        || (typeof this.threeDSPaymentInfo.threeDS.notifyURL == "string" && this.getHostName(event.origin) == this.getHostName(this.threeDSPaymentInfo.threeDS.notifyURL)))
        && ((source == "TDSv2" && !this.tdsV2.threeDSMethodFailure) || (source == "TDSv1" && !this.tdsV1.threeDSMethodFailure))){ 

            let threeDSServerTransID = null;
            if(typeof event.data.threeDSMethodData == "string")threeDSServerTransID = event.data.threeDSMethodData;
            if(typeof event.data.threeDSServerTransID == "string")threeDSServerTransID = event.data.threeDSServerTransID;

            if(typeof threeDSServerTransID == "string"){
                //TDSv2 threeDSMethodData
                this.tdsV2.checkAuth(threeDSServerTransID, "Y").then((result:any) => {


                    this.handleCheckTDSAuthResult(result);
                });
                TDSManager.stopPostMessagePropagation(event);
                window.removeEventListener("message", this._handleThreeDSMethodCallback);

            }else if(typeof event.data.PaRes == "string"){
                 //TDSv1 PaRes
                this.tdsV1.checkPARes(event.data.PaRes).then((result:any) => {
                    this.returnMWCallBack(result.data);
                });
                TDSManager.stopPostMessagePropagation(event);
                window.removeEventListener("message", this._handleThreeDSV1MethodCallback);
            }

        }
    }

    public handleCheckTDSAuthResult(result:any){

        if(result.requiredAction == "challenge"){
            this.tdsV2.setTDSCardToken(result);
            this.tdsV2.createChallengeIframe(result);
        }else if(result.requiredAction == "frictionless"){
            this.tdsV2.setTDSCardToken(result);
            this.returnMWCallBack(result.data);
        }else if(result.requiredAction == "error"){
            this.returnMWCallBack(result.data);
        }
    }


    public handleChallengeCallback(event:any){
        if(((this.getHostName(event.origin) == this.getHostName(this.getMWThreeDSNotifyURL()))
        || (typeof this.threeDSPaymentInfo.threeDS.notifyURL == "string" && this.getHostName(event.origin) == this.getHostName(this.threeDSPaymentInfo.threeDS.notifyURL)))
        && !this.tdsV2.challengeFlowFailure){ 

            if(typeof event.data.cres == "string"){
                this.tdsV2.checkPARes(event.data.cres).then((result:any) => {
                    this.handleCheckPAResResult(result);
                });
                TDSManager.stopPostMessagePropagation(event);
                window.removeEventListener("message", this._handleChallengeCallback);
            }


        }
    }

    public handleCheckPAResResult(result:any){
        this.returnMWCallBack(result.data);
    }

    public setThreeDSData(threeDSToken:string){
        this.threeDSResult.threeDSToken = threeDSToken;
    }

    public getThreeDSData(){
        return this.threeDSResult;
    }


    public getMWThreeDSNotifyURL() : string{
        return this.controller.getOptions().api + "threeds/notify";
    }

    public returnMWCallBack(result:any){
        var cardToken = this.tdsV2.getTDSCardToken() ?? null;
        if(cardToken.tdsPayKey && cardToken.tdsPayToken){result = Object.assign(result, {tdsPayKey : cardToken.tdsPayKey,tdsPayToken : cardToken.tdsPayToken});}

        if(this.mwCallback){
            this.mwCallback(result)
        }else{
            return result;
        }
    }

    public makeXHRRequest(request:any): Promise<any> {

        if(this.controller.getMerchantAccessToken()) {
            request = Object.assign({
                accessToken: this.controller.getMerchantAccessToken(),
            }, request); // requestCustomer disregarded if undefined
        } else {
            request = Object.assign({
                merchantUUID: this.controller.getMerchantUUID(),
                apiKey: this.controller.getMerchantApiKey(),
            }, request);
        }


        let apiURL = this.controller.getOptions().api + "threeds/";
        return new Promise(function (resolve, reject) {
            var xhttp = new XMLHttpRequest();
            xhttp.open("POST", apiURL, true);
            xhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            xhttp.onload = function () {
                if (xhttp.status >= 200 && xhttp.status < 300) {
                    resolve(xhttp.responseXML);
                } else {
                    reject({
                        status: xhttp.status,
                        statusText: xhttp.statusText
                    });
                }
            };
            xhttp.onerror = function () {
                reject({
                    status: xhttp.status,
                    statusText: xhttp.statusText
                });
            };
            var requestString = '';
			Object.getOwnPropertyNames(request).forEach(function(element) {
				if(requestString)requestString += '&';
                requestString += encodeURIComponent(element) +'=' + encodeURIComponent(request[element]);
			});
			xhttp.send(requestString);
          });

        //Access-Control-Allow-Headers
    }

    public static stopPostMessagePropagation(event : Event){
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    public static getXMLValue(xml:any, entry:any){
        if(xml.getElementsByTagName(entry).length>0 &&  xml.getElementsByTagName(entry)[0].childNodes[0])
            return xml.getElementsByTagName(entry)[0].childNodes[0].nodeValue;
    }

    public static formErrorResponse(result:any){
        return {
            responseCode : TDSManager.getXMLValue(result, "responseCode"),
            responseMessage : TDSManager.getXMLValue(result, "responseMessage"),
            transStatus : TDSManager.getXMLValue(result, "transStatus")
        };
    }
    private getHostName(string: string) {
        var a = document.createElement('a');
        a.href = string;
        return a.hostname;
    };

    public sendMessage(msg : any, target : string = '*') {
        window.parent.postMessage(msg, target);
    };

    private cleanThreeDSPaymentInfo(threeDSPaymentInfo: threeDSPaymentInfo){

        //process forter customer fields
        if(threeDSPaymentInfo.forterCustoemrFields){
            if(!threeDSPaymentInfo.billingAddress.billAddrLine1 && typeof threeDSPaymentInfo.forterCustoemrFields.customerAddress == "string")threeDSPaymentInfo.billingAddress.billAddrLine1 = threeDSPaymentInfo.forterCustoemrFields.customerAddress;
            if(!threeDSPaymentInfo.customerName && typeof threeDSPaymentInfo.forterCustoemrFields.customerName == "string")threeDSPaymentInfo.customerName = threeDSPaymentInfo.forterCustoemrFields.customerName;
            if(!threeDSPaymentInfo.customerEmail && typeof threeDSPaymentInfo.forterCustoemrFields.customerEmail == "string")threeDSPaymentInfo.customerEmail = threeDSPaymentInfo.forterCustoemrFields.customerEmail;
            if(!threeDSPaymentInfo.billingAddress.billAddrCity && typeof threeDSPaymentInfo.forterCustoemrFields.customerCity == "string")threeDSPaymentInfo.billingAddress.billAddrCity = threeDSPaymentInfo.forterCustoemrFields.customerCity;
            if(!threeDSPaymentInfo.billingAddress.billAddrCountry && typeof threeDSPaymentInfo.forterCustoemrFields.customerCountry == "string")threeDSPaymentInfo.billingAddress.billAddrCountry = threeDSPaymentInfo.forterCustoemrFields.customerCountry;
            if(!threeDSPaymentInfo.billingAddress.billAddrPostCode && typeof threeDSPaymentInfo.forterCustoemrFields.customerPostCode == "string")threeDSPaymentInfo.billingAddress.billAddrPostCode = threeDSPaymentInfo.forterCustoemrFields.customerPostCode;
            if(!threeDSPaymentInfo.customerPhone && typeof threeDSPaymentInfo.forterCustoemrFields.customerPhone == "string")threeDSPaymentInfo.customerPhone = threeDSPaymentInfo.forterCustoemrFields.customerPhone;
            if(!threeDSPaymentInfo.billingAddress.billAddrState && typeof threeDSPaymentInfo.forterCustoemrFields.customerState == "string")threeDSPaymentInfo.billingAddress.billAddrState = threeDSPaymentInfo.forterCustoemrFields.customerState;
        }

        return threeDSPaymentInfo;
    }
}