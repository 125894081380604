import { Util } from './Util';
import { MerchantInfo, MerchantInfoResponse, MerchantWarriorOptions, PaymentSummary, AddCardSummary, MethodData, APIRequest,threeDSPaymentInfo,DynamicPaymentItem} from './Types';
import { Customer, RequestCustomer } from './Customer';
import { Element } from './Element';
import { DigitalWalletWrapper } from './Wallets/DigitalWalletWrapper';
import { DigitalWallet } from './Wallets/DigitalWallet';
import { EventManager } from 'lipwig-events';
import { ConsolidatedWallet } from './Wallets/ConsolidatedWallet';
import { ApplePay } from './Wallets/ApplePay';
import { TDSManager } from './ThreeDS/TDSManager';
import {ApplePayIframe} from "./Wallets/ApplePayIframe";
import {PayframeController} from "./Payframe/PayframeController";
import { ApplePayIframe2 } from './Wallets/ApplePayIframe2';

export class MerchantWarriorController extends EventManager {
    private uuid?: string;
    private apiKey?: string;
    private accessToken?: string;
    private options: MerchantWarriorOptions;
    private info: MerchantInfo; 
    public version: string;

    public static getVersion(): string {
        const environment = process.env.ENVIRONMENT ?? '';
        const version = process.env.VERSION ?? '';

        return environment + '.' + version;
    }

    constructor(uuid: string, apiKey: string, options: MerchantWarriorOptions, info: MerchantInfo) {
        super();
        this.version = MerchantWarriorController.getVersion();
        if(apiKey.length == 0) {
            this.accessToken = uuid;
            if(typeof info.uuid === 'string') {
                this.uuid = info.uuid;
            }
        } else {
            this.uuid = uuid;
            this.apiKey = apiKey;
        }
        options = Util.fillOptions(options);
        this.options = options;
        this.info = info;

        const existing = document.getElementsByClassName('mw-style');
        if (existing.length == 0) {
            const style = <HTMLLinkElement> document.createElement('link');
            style.rel = 'stylesheet';
            style.type = 'text/css';
            style.href = this.options.fuse + '/sdk/merchantwarrior.css';
            style.classList.add('mw-style');
            document.head.appendChild(style);
        }
    }

    public paymentRequest(summary: PaymentSummary): Promise<Element> {
        summary.style = summary.style ?? 'dark';
        summary.requestCustomerDetails = summary.requestCustomerDetails ?? false;

        if(typeof summary.transaction?.currency == "string") this.info.currency = summary.transaction.currency;
        if(typeof summary.transaction?.transactionCurrency == "string") this.info.currency = summary.transaction.transactionCurrency;
        return DigitalWalletWrapper.getWallets(this, summary).then((wallets: DigitalWallet[]) => {
            if (summary.group) {
                if (!window.PaymentRequest) {
                    throw new Error('Cannot build Payment Request button - unsupported by browser');
                }

                return this.getConsolidatedWalletButton(summary, wallets);

            }

            return this.getIndividualButtons(wallets);
        });
    }

    public addWalletCard(summary: AddCardSummary): Promise<Element> {
        summary.style = summary.style ?? 'dark';
        summary.text = summary.text ?? 'Save Card (No Charge)';
        summary.group = summary.group ?? false;
        summary.wallets = summary.wallets ?? [];

        const paymentSummary: PaymentSummary = {
            total: {
                amount: summary.max,
                label: summary.text,
                pending: false
            },
            style: summary.style,
            addCard: true,
            wallets: summary.wallets,
            group: summary.group,
        }
        return DigitalWalletWrapper.getWallets(this, paymentSummary).then((wallets: DigitalWallet[]) => {
            if (paymentSummary.group) {
                if (!window.PaymentRequest) {
                    throw new Error('Cannot build Payment Request button - unsupported by browser');
                }

                return this.getConsolidatedWalletButton(paymentSummary, wallets, false);
            }

            return this.getIndividualButtons(wallets, false);
        })
    }

    //Note - eventually we should move this the DW Wrapper
    private getConsolidatedWalletButton(summary: PaymentSummary, wallets: DigitalWallet[], pay: boolean = true): Promise<Element> {
        return ConsolidatedWallet.create(this, summary, wallets).then((wallet: ConsolidatedWallet | ApplePay | ApplePayIframe2) => {
            if (pay) {
                return wallet.getPayButton()
            }

            return wallet.getAddButton();
        }).then((button: HTMLElement) => {
            const element = new Element();
            element.add(button);
            return element;
        });
    }
    
    private getIndividualButtons(wallets: DigitalWallet[], pay: boolean = true): Promise<Element> {
        const buttonPromises: Promise<HTMLElement>[] = []
        for (let i = 0; i < wallets.length; i++) {
            const wallet = wallets[i];
            if (pay) {
                const buttonPromise = wallet.getPayButton();
                buttonPromises.push(buttonPromise);
            } else {
                const buttonPromise = wallet.getAddButton();
                buttonPromises.push(buttonPromise);
            }
        }

        // This could be changed to allSettled, but as-is the getWallets method should filter out invalid buttons
        return Promise.all(buttonPromises).then((buttons: HTMLElement[]) => {
            const element = new Element();
            for (let i = 0; i < buttons.length; i++) {
                const button: HTMLElement = buttons[i];
                element.add(button);
            }

            return element;
        });
    }

    private getIframeWalletButton(summary: PaymentSummary, wallets: DigitalWallet[], pay: boolean = true): Promise<Element> {
        const iframePromises: Promise<HTMLElement>[] = []
        for (let i = 0; i < wallets.length; i++) {
            const wallet = wallets[i];
            if (pay) {
                const buttonPromise = wallet.getPayButton();
                iframePromises.push(buttonPromise);
            } else {
                const buttonPromise = wallet.getAddButton();
                iframePromises.push(buttonPromise);
            }
        }

        // This could be changed to allSettled, but as-is the getWallets method should filter out invalid buttons
        return Promise.all(iframePromises).then((iframes: HTMLElement[]) => {
            const element = new Element();
            for (let i = 0; i < iframes.length; i++) {
                const iframe: HTMLElement = iframes[i];
                element.add(iframe);
            }

            return element;
        });
    }

    public makeRequest(request: APIRequest, customer?: Customer): Promise<any> {
        let requestCustomer: RequestCustomer | undefined = undefined;
        if (customer) {
            requestCustomer = Util.formatCustomer(customer);
        }

        let requestData: APIRequest;

        requestData = {
            method: '',
            endpoint: this.options.api,
        };

        if(typeof this.accessToken == 'string') {
            requestData.accessToken = this.accessToken;
        } else {
            requestData.merchantUUID = this.uuid;
            requestData.apiKey = this.apiKey;
        }
        var requestObject = Object.assign(requestData, request, requestCustomer); // requestCustomer disregarded if undefined

        if(this.options.autoProcess){
            //ALL IN ONE autoRequest
            if(typeof requestObject.accessToken != 'string'){
                throw new Error('AccessToken is required filed for autoRequest option');
            }
            if(this.options.xcsrfToken.length > 0){
                throw new Error('xcsrfToken feature only supported in Server-end Middleware mode');
            }
            requestObject.endpoint += 'websdk/';
            return fetch(requestObject.endpoint, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(requestObject)
            }).then((response: Response) => {
                return response.json();
            });
        }else if (typeof this.options.middleware === 'string') {
            //Sever-end Middleware 
            return fetch(this.options.middleware, {
                method: 'POST',
                headers: this.options.xcsrfToken.length > 0 ? {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': this.options.xcsrfToken
                } : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestObject)
            }).then((response: Response) => {

                return response.json();
            });
        } else {
            //Front-end Middleware
            return this.options.middleware(requestObject);
        }
    }



    public createTDSManager(paymentInfo:threeDSPaymentInfo, containerDivId:string = "threeds-container"){
        return new TDSManager(this, paymentInfo, containerDivId);
        
    }

    public getMerchantUUID(): string {
        if(typeof this.uuid === 'string') {
            return this.uuid;
        } else {
            return '';
        }
    }
    public getMerchantApiKey(): string {
        if(typeof this.apiKey === 'string') {
            return this.apiKey;
        } else {
            return '';
        }
    }
    public getMerchantAccessToken(): string {
        if(typeof this.accessToken === 'string') {
            return this.accessToken;
        } else {
            return '';
        }
    }

    public getOptions(): MerchantWarriorOptions {
        return this.options;
    }

    public getInfo(): MerchantInfo {
        return this.info;
    }

    /**
     * Load payframe, will add the script to the body and load the payframe instance for the element passed
     * @param method
     * @param elementId
     * @param summary
     * @param tdsElementId optional
     */
    public loadPayframe(method: string = 'getPayframeToken', elementId : string, summary: PaymentSummary, tdsElementId?: string ): Promise<payframe> {
        if(summary.payframe == undefined){
            throw new Error('Could not find payframe options');
        }

        return Promise.resolve(PayframeController.create(this, method, elementId, summary, tdsElementId))
            .then((mwPayframe: payframe | void | null) => {
                if(mwPayframe instanceof payframe){
                    return Promise.resolve(mwPayframe);
                }
                return Promise.reject("Could not initiate Payframe");
            });

    }

    /**
     * Stops the propagation of a MessageEvent to prevent further event handling.
     * @param event The MessageEvent to stop propagation for.
     */
    public static stopEventPropagation(event:MessageEvent){
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    public static getTotalPrice(summary: PaymentSummary){
        let totalPrice: string;
        if (typeof summary.total.amount == 'string') {
            totalPrice = summary.total.amount;
        } else {
            totalPrice = summary.total.amount.value;
        }
        return totalPrice;
    }

    public updateAccessToken(accessToken: string){
        this.accessToken = accessToken;
    }

    public directRequest(processRequest: APIRequest){
        const directRequestAllowedMethod = ['addCard'];
        if(typeof processRequest.method != "string" || !directRequestAllowedMethod.includes(processRequest.method)){
            throw new Error('Invalid method for the directRequest option.');
        }
        //Auto Process Router would check the 
        this.options.autoProcess = true;

        if(typeof processRequest.loading == 'function') processRequest.loading();

        this.makeRequest(processRequest).then((response) => {
            if(typeof processRequest.loaded == 'function') 
                processRequest.loaded();
            
            if (response.responseCode == '0') {
                const status = response.responseCode == '0';
                this.emit('card-added', status, response);
            }else{
                this.emit('card-added', false, response);
            }
        });

        return;
    }
}
