import {PaymentSummary, MethodData, APIRequest, PayframeOptions, PayframeObject, PayframeTdsObject} from '../Types';
import {MerchantWarriorController} from '../MerchantWarriorController';

export class PayframeController {
    private controller: MerchantWarriorController;
    private data: any;
    private summary: PaymentSummary;
    public mwPayframe: payframe | null;
    private readonly submitUrl: string;
    private readonly src: string;

    constructor(controller: MerchantWarriorController, summary: PaymentSummary, data: any) {
        this.data = data;
        this.summary = summary;
        this.controller = controller;
        this.mwPayframe = null;
        if (this.summary.payframe?.submitUrl) {
            this.submitUrl = this.summary.payframe.submitUrl;
        } else {
            this.submitUrl = process.env.MW_API_URL ?? '';
        }
        this.src = process.env.MW_PAYFRAME_URL ?? '';
    }

    /**
     * Create payframe instance
     * @param controller
     * @param method
     * @param summary
     * @param tdsElementId optional
     */
    public static create(controller: MerchantWarriorController, method: string, elementId: string, summary: PaymentSummary, tdsElementId?: string): Promise<payframe | void> {

        return PayframeController.loadPayframeJS(summary)
            .then(() => {
                return Promise.resolve(new PayframeController(controller, summary, {}));
            }).then((payframeController: PayframeController) => {
                const mwPayframeInstance = payframeController.loadMWPayframeInstance(method, elementId);
                if (mwPayframeInstance == null) {
                    throw new Error('Payframe could not be initiated');
                }
                return payframeController;
            }).then((payframeController: PayframeController) => {
                if(payframeController.mwPayframe != null){
                    const customerData = payframeController.formatCustomerData(summary);
                    if(customerData) {
                        payframeController.mwPayframe._customerData = customerData;
                    }
                    if (summary.payframe?.threeDS) {
                        const tdsElement = tdsElementId ? tdsElementId : elementId;
                        payframeController.loadPayframeTdsInstance(summary, tdsElement);
                    }
                    return payframeController.mwPayframe;
                }
                throw new Error('PayframeController could not load');
            }).catch((error) => {
                throw new Error('PayframeController could not load');
            });
    }

    /**
     * Load payframe instance
     * @param method
     * @param payframeDivId
     * @private
     */
    private loadMWPayframeInstance(method: string, payframeDivId: string): payframe | null {
        const accessToken = this.controller.getMerchantAccessToken();
        const styles = this.summary.payframe?.styles ?? {};
        const acceptedCardTypes = this.summary.payframe?.acceptedCardTypes ?? '';

        if (accessToken.length > 0) {
            let config: PayframeObject = {
                payframeDivId,
                accessToken: this.controller.getMerchantAccessToken(),
                src: this.src,
                submitUrl: this.submitUrl,
                style: styles,
                method,
                acceptedCardTypes,
                displayCVV: this.summary.payframe?.displayCVV
            };
            this.mwPayframe = new payframe(config);
        } else if (this.controller.getMerchantUUID().length > 0 && this.controller.getMerchantApiKey().length > 0) {
            this.mwPayframe = new payframe(
                this.controller.getMerchantUUID(),
                this.controller.getMerchantApiKey(),
                payframeDivId,
                this.src,
                this.submitUrl,
                styles,
                acceptedCardTypes,
                method
            );
        }
        return this.mwPayframe;
    }

    /**
     * Format customer data for sending to payframe
     * @param summary
     * @private
     */
    private formatCustomerData(summary: PaymentSummary): object | boolean {
        const customerData = {
            'transactionAmount': summary.total.amount,
            'transactionCurrency': summary.transaction?.currency,
            'customerName': summary.customer?.name,
            'customerAddress': summary.customer?.address,
            'customerCity': summary.customer?.city,
            'customerState': summary.customer?.state,
            'customerCountry': summary.customer?.country,
            'customerPostCode': summary.customer?.postCode,
            'customerPhone': summary.customer?.phone,
            'customerEmail': summary.customer?.email
        };
        return customerData;
    }


    /**
     * Load the payframe js by checking on the summary version
     * @param summary
     * @private
     */
    private static loadPayframeJS(summary: PaymentSummary): Promise<any> {
        const version = summary.payframe?.version || 'latest';
        let src = process.env.MW_PAYFRAME_URL ?? '';
        if (version == 'latest') {
            src += 'payframe.js';
        } else {
            src += 'payframe' + version + '.js';
        }

        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.onload = resolve;
            script.onerror = reject;
            script.src = src;
            document.body.append(script);
        })
    }


    /**
     * Load the tds Check instance from payframe to the payframe instance
     * @param summary
     * @param tdsElementId
     */
    public loadPayframeTdsInstance(summary: PaymentSummary, tdsElementId: string) {

        if (this.controller == null || this.mwPayframe == null) {
            throw new Error('PayframeController could not load TDS');
            return;
        }

        const accessToken = this.controller.getMerchantAccessToken();
        const styles = this.summary.payframe?.tdsStyles ?? {};
        let tdsCheckInstance = null;

        if (accessToken.length > 0) {
            let config: PayframeTdsObject = {
                accessToken: this.controller.getMerchantAccessToken(),
                tdsDivId: tdsElementId,
                submitUrl: this.submitUrl,
                style: styles,
            };
            tdsCheckInstance = new tdsCheck(config);
        } else if (this.controller.getMerchantUUID().length > 0 && this.controller.getMerchantApiKey().length > 0) {
            tdsCheckInstance = new tdsCheck(
                this.controller.getMerchantUUID(),
                this.controller.getMerchantApiKey(),
                tdsElementId,
                this.submitUrl,
                styles,
            );
        }

        if (tdsCheckInstance != null) {
            tdsCheckInstance.link(this.mwPayframe);
            this.mwPayframe.tdsCheck = tdsCheckInstance;
        }

    }


}
