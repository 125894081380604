import { Util } from '../Util';
import { Customer } from '../Customer';
import { PaymentSummary, MethodData, APIRequest,threeDSPaymentInfo} from '../Types';
import { MerchantWarriorController } from '../MerchantWarriorController';
import { TDSManager } from './TDSManager';
import { TDS } from './TDS';
import { resolve } from 'path';


export class TDSv2 implements TDS {

    private tdsManager: TDSManager;
    private threeDSPaymentInfo: threeDSPaymentInfo;

    private data: any;
    private mwHandleNotifyURL = true;
    private threeDSNotifyURL: string;

    private tdsCardToken: any;

    public threeDSMethodFailure:boolean;
    public threeDSMethodFailureoverControl:any;
    public challengeFlowFailure:boolean;
    public challengeFlowFailureoverControl:any;
    public supportMode:string;

    // private threeDSServerTransID:string;

    constructor(tdsManager : TDSManager,threeDSPaymentInfo: threeDSPaymentInfo) {
        this.tdsManager = tdsManager;
        this.threeDSPaymentInfo = threeDSPaymentInfo;
        if(typeof this.threeDSPaymentInfo.threeDS.notifyURL != "string" ){
            // console.log("MW will handle the TDSv2 callbackURL, checkTDSAvailable in all mode");
            this.mwHandleNotifyURL = true;
            this.threeDSNotifyURL = this.tdsManager.getMWThreeDSNotifyURL();
        }else{
            this.mwHandleNotifyURL = false;
            this.threeDSNotifyURL = this.threeDSPaymentInfo.threeDS.notifyURL;
        }
        this.tdsCardToken = {
            tdsPayKey : "",
            tdsPayToken : ""
        };
        this.threeDSMethodFailure = false;
        this.threeDSMethodFailureoverControl = null;
        this.challengeFlowFailure = false;
        this.challengeFlowFailureoverControl = null;
        this.supportMode = "";
        if(threeDSPaymentInfo.threeDS.support)this.supportMode = threeDSPaymentInfo.threeDS.support;


    }

    public checkEnrollment(): any {

        var checkEnrollmentResponse = {
            requiredAction: "None",
            version: "2.0",
            data:{
            },
            
        }
        
        const result:any = this.startCheckEnrollment();
        return result.then(function(result:any){
            const responseCode = result.getElementsByTagName("responseCode");

            if(responseCode && responseCode.length > 0 && responseCode[0].childNodes.length > 0) {
                const responseCodeValue = result.getElementsByTagName("responseCode")[0].childNodes[0].nodeValue;
                if(responseCodeValue == '0'){

                    if(result.getElementsByTagName("acsURL").length > 0 && result.getElementsByTagName("acsURL")[0].childNodes[0] 
                    && result.getElementsByTagName("paReq").length > 0 && result.getElementsByTagName("paReq")[0].childNodes[0]){
                        checkEnrollmentResponse.requiredAction = "threeDSMethodIframe";
                        checkEnrollmentResponse.data = {
                            acsURL : result.getElementsByTagName("acsURL")[0].childNodes[0].nodeValue,
                            paReq : result.getElementsByTagName("paReq")[0].childNodes[0].nodeValue,
                        }
                    }else if(result.getElementsByTagName("threeDSServerTransID").length > 0 && result.getElementsByTagName("threeDSServerTransID")[0].childNodes[0] 
                    && result.getElementsByTagName("threeDSCompInd").length > 0 && result.getElementsByTagName("threeDSCompInd")[0].childNodes[0]){
                        checkEnrollmentResponse.requiredAction = "checkTDSAuth";
                        checkEnrollmentResponse.data = {
                            threeDSServerTransID : result.getElementsByTagName("threeDSServerTransID")[0].childNodes[0].nodeValue,
                            threeDSCompInd : result.getElementsByTagName("threeDSCompInd")[0].childNodes[0].nodeValue,
                        }
                    }

                    if(result.getElementsByTagName("notifyURL").length == 0){
                        checkEnrollmentResponse.version = "1.0";
                    }

                    if(checkEnrollmentResponse.requiredAction == "None"){
                        var enrolled = TDSManager.getXMLValue(result, "enrolled");
                        checkEnrollmentResponse.data = {
                            responseCode : TDSManager.getXMLValue(result, "responseCode"),
                            responseMessage : TDSManager.getXMLValue(result, "responseMessage"),
                            enrolled : (enrolled) ? enrolled : "N",
                            threeDSEci : TDSManager.getXMLValue(result, "eci"),
                        }
                    }

                }else{
                    //Error Message
                    checkEnrollmentResponse.data = TDSManager.formErrorResponse(result);
                }
            }

            return checkEnrollmentResponse;

        })    
    }
    public checkAuth(threeDSServerTransID:string, threeDSCompInd:string = "Y"): any {

        var currentData = new Date();

        let purchaseDate = currentData.getUTCFullYear().toString() + this.pad2(currentData.getUTCMonth() + 1) + 
                            this.pad2( currentData.getUTCDate()) + this.pad2( currentData.getUTCHours() ) + 
                            this.pad2( currentData.getUTCMinutes() ) + this.pad2( currentData.getUTCSeconds() );

        const processCardRequest: APIRequest = {
            method: 'checkTDSAuth',

            paymentCardNumber: this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardNumber,
            paymentCardExpiry: this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardExpiry,
            paymentCardName: this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardName,
            customerName: this.threeDSPaymentInfo.customerName,
            customerEmail: (this.threeDSPaymentInfo.customerEmail) ? this.threeDSPaymentInfo.customerEmail : "",
            customerPhone: (this.threeDSPaymentInfo.customerPhone) ? this.threeDSPaymentInfo.customerPhone : "",
            transactionAmount:  this.threeDSPaymentInfo.transactionInfo.transactionAmount,
            transactionCurrency:  this.threeDSPaymentInfo.transactionInfo.transactionCurrency,
            transactionProduct: this.threeDSPaymentInfo.transactionInfo.transactionProduct,
            notifyURL:  this.threeDSNotifyURL,           
            browserAcceptHeader: "", //this field will be handled in our server side
            browserColorDepth:screen.colorDepth,
            browserJavaEnabled:navigator.javaEnabled(),
            browserJavascriptEnabled: true,
            browserLanguage:navigator.language,
            browserScreenHeight:screen.height,
            browserScreenWidth:screen.width,
            browserTZ:currentData.getTimezoneOffset(),
            browserUserAgent: navigator.userAgent,
            purchaseDate: purchaseDate,
            billAddrCity: this.threeDSPaymentInfo.billingAddress.billAddrCity,
            billAddrCountry: this.threeDSPaymentInfo.billingAddress.billAddrCountry,
            billAddrLine1: this.threeDSPaymentInfo.billingAddress.billAddrLine1,
            billAddrPostCode: this.threeDSPaymentInfo.billingAddress.billAddrPostCode,
            billAddrState: this.threeDSPaymentInfo.billingAddress.billAddrState,
            threeDSCompInd:threeDSCompInd,
            threeDSRequestorAuthenticationInd:"01", //01-payment transaction; 02-recurring transaction; 03-instalment transaction; etc....
            threeDSRequestorURL: window.location.protocol + "//" + window.location.hostname,
            challengeWindowSize: "01",
            source: "WEB-SDK"

        }


        if(threeDSServerTransID) processCardRequest.threeDSServerTransID = threeDSServerTransID;
        if(this.threeDSPaymentInfo.threeDS.threeDSRequestorAuthenticationInd) processCardRequest.threeDSRequestorAuthenticationInd =this.threeDSPaymentInfo.threeDS.threeDSRequestorAuthenticationInd;
        if(this.threeDSPaymentInfo.threeDS.threeDSRequestorChallengeInd) processCardRequest.threeDSRequestorChallengeInd =this.threeDSPaymentInfo.threeDS.threeDSRequestorChallengeInd;
        if(this.threeDSPaymentInfo.threeDS.challengeWindowSize) processCardRequest.challengeWindowSize =this.threeDSPaymentInfo.threeDS.challengeWindowSize;
        if(this.threeDSPaymentInfo.threeDS.merchantName) processCardRequest.merchantName =this.threeDSPaymentInfo.threeDS.merchantName;

        //payframe Token
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeToken) processCardRequest.payframeToken = this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeToken;
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeKey) processCardRequest.payframeKey = this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeKey;
        //cardID
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.cardID) processCardRequest.cardID = this.threeDSPaymentInfo.transactionInfo.cardInfo.cardID;
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.cardKey) processCardRequest.cardKey = this.threeDSPaymentInfo.transactionInfo.cardInfo.cardKey;

        //DigitalWalletToken
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.digitalWalletToken) processCardRequest.digitalWalletToken = this.threeDSPaymentInfo.transactionInfo.cardInfo.digitalWalletToken;
        
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardCSC) processCardRequest.paymentCardCSC = this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardCSC;

        if(this.threeDSPaymentInfo.threeDS.source) processCardRequest.source = this.threeDSPaymentInfo.threeDS.source;

        //Payframe Legacy support for validation-check
        //Default Payframe TDS Mode for performance
        if(this.supportMode) processCardRequest.support = this.supportMode;


        if(this.threeDSPaymentInfo.transactionInfo.tdsDCC) {
            processCardRequest.tdsDCC = this.threeDSPaymentInfo.transactionInfo.tdsDCC;
            processCardRequest.originalAmount = this.threeDSPaymentInfo.transactionInfo.originalAmount;
            processCardRequest.originalCurrency = this.threeDSPaymentInfo.transactionInfo.originalCurrency;
        }

        const requestPromise = this.tdsManager.makeXHRRequest(processCardRequest);

        var checkTDSAuthResponse = {
            requiredAction: "error",
            version: "2.0",
            data:{
            },
            cardToken : {
                tdsPayKey: "",
                tdsPayToken: ""
            }
        }

        return requestPromise.then(function(result:any){

            const responseCode = result.getElementsByTagName("responseCode");
            if(responseCode && responseCode.length > 0 && responseCode[0].childNodes.length > 0) {
                const responseCodeValue = result.getElementsByTagName("responseCode")[0].childNodes[0].nodeValue;
                if(responseCodeValue == '0'){
                    if(result.getElementsByTagName("acsURL") && result.getElementsByTagName("challengeData") && result.getElementsByTagName("acsURL").length > 0 && result.getElementsByTagName("challengeData").length > 0){
                        checkTDSAuthResponse.requiredAction = "challenge";
                        checkTDSAuthResponse.data = {
                            acsURL : result.getElementsByTagName("acsURL")[0].childNodes[0].nodeValue,
                            challengeData : result.getElementsByTagName("challengeData")[0].childNodes[0].nodeValue,
                        };

                    }else if(result.getElementsByTagName("threeDSStatus") && result.getElementsByTagName("threeDSStatus").length > 0 
                    && result.getElementsByTagName("authenticationFlow") && result.getElementsByTagName("authenticationFlow").length > 0 
                    && result.getElementsByTagName("authenticationFlow")[0].childNodes[0].nodeValue == "frictionless"){
                        // TDSv2transStatus = result.getElementsByTagName("TDSv2transStatus")[0].childNodes[0].nodeValue;
                        checkTDSAuthResponse.requiredAction = "frictionless";
                        var liabilityShift = TDSManager.getXMLValue(result, "liabilityShift");

                        checkTDSAuthResponse.data = {
                            threeDSEci: TDSManager.getXMLValue(result, "threeDSEci"),
                            threeDSCavv: TDSManager.getXMLValue(result, "threeDSCavv"),
                            threeDSToken: TDSManager.getXMLValue(result, "threeDSToken"),
                            threeDSXid:TDSManager.getXMLValue(result, "threeDSXid"),
                            threeDSV2Version: TDSManager.getXMLValue(result, "threeDSV2Version"),
                            threeDSStatus: TDSManager.getXMLValue(result, "threeDSStatus"),
                            threeDSStatusReason: TDSManager.getXMLValue(result, "threeDSStatusReason"),
                            liabilityShift: (liabilityShift) ? liabilityShift : "N",
                        };
                    }

                    if(result.getElementsByTagName("tdsPayKey") && result.getElementsByTagName("tdsPayToken") && result.getElementsByTagName("tdsPayKey").length > 0 && result.getElementsByTagName("tdsPayToken").length > 0){
                        checkTDSAuthResponse.cardToken.tdsPayKey = TDSManager.getXMLValue(result, "tdsPayKey");
                        checkTDSAuthResponse.cardToken.tdsPayToken = TDSManager.getXMLValue(result, "tdsPayToken");  
                    }   
                }else{
                    //Error Message
                    checkTDSAuthResponse.data = TDSManager.formErrorResponse(result);
                }
            }


            return checkTDSAuthResponse;

        })   
    }
    public checkPARes(paymentDetail : any): Promise<any> {
        setTimeout(() => {
            if(typeof this.tdsManager.loading == 'function' && document.getElementById("mwIframe")) this.tdsManager.loading();
        }, 500); //the reason we need delayed function, because there would be conflict with challenge-ifram-onload loaded function


        if(this.tdsManager.iFrameMode == "subFrame"){
            var msg = {
                mwTDSResult: "TDSPayframeSubFrameAction",
                requiredAction : "loading"
                } 
                var jsnMsg = JSON.stringify(msg);
                this.tdsManager.sendMessage(jsnMsg)
        }


        const processCardRequest: APIRequest = {
            method: 'checkPARes',
            source: "WEB-SDK",
            PARes: paymentDetail
        }
        if(this.supportMode) processCardRequest.support = this.supportMode;
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeToken) processCardRequest.payframeToken = this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeToken;

        const requestPromise = this.tdsManager.makeXHRRequest(processCardRequest);


        var checkPAResResponse = {
            responseCode: "",
            data: {}
        };


        return requestPromise.then(function(result:any){

            const responseCode = result.getElementsByTagName("responseCode");
            if(responseCode && responseCode.length > 0 && responseCode[0].childNodes.length > 0) {
                const responseCodeValue = result.getElementsByTagName("responseCode")[0].childNodes[0].nodeValue;
                if(responseCodeValue == '0'){
                    checkPAResResponse.responseCode = "0";
                    var liabilityShift = TDSManager.getXMLValue(result, "liabilityShift");
                    
                    checkPAResResponse.data = {
                        threeDSEci: TDSManager.getXMLValue(result, "threeDSEci"),
                        threeDSCavv: TDSManager.getXMLValue(result, "threeDSCavv"),
                        threeDSToken: TDSManager.getXMLValue(result, "threeDSToken"),
                        threeDSXid:TDSManager.getXMLValue(result, "threeDSXid"),
                        threeDSV2Version: TDSManager.getXMLValue(result, "threeDSV2Version"),
                        threeDSStatus: TDSManager.getXMLValue(result, "threeDSStatus"),
                        threeDSStatusReason: TDSManager.getXMLValue(result, "threeDSStatusReason"),
                        liabilityShift: (liabilityShift) ? liabilityShift : "N",
                    }
                }else{
                    checkPAResResponse.data = TDSManager.formErrorResponse(result);
                }
            }else{
                //No responseCode
            }

            return checkPAResResponse;

        })   

    }
    

    public createChallengeIframe(requestDetail:any): boolean {

        const displayBox = document.getElementById(this.tdsManager.containerDivId); 

        let iframe = document.createElement('iframe');
        iframe.classList.add('tdsv2challengeIframe');
        iframe.name = "challengeIframe";
        iframe.id = "3dsv2challengeIframe";
        iframe.height = this.tdsManager.challengeHeight;
        iframe.width = this.tdsManager.challengeWidth;
        displayBox!.appendChild(iframe);

        var form = document.createElement("form");
        form.classList.add('hidden_TDS_From');
        form.id = "3dsv2challengeForm";
        displayBox!.appendChild(form);

        var input = document.createElement("input");
        input.id = "creq";
        input.name = "creq";
        input.value = requestDetail.data.challengeData;
        input.type = "hidden";
        form.appendChild(input);

        //replicate 1.0 style
        iframe.setAttribute('allowtransparency','true');
        iframe.setAttribute('frameborder','0');
        

        form.action = requestDetail.data.acsURL;
        form.target = iframe.name;
        form.method = 'post';
        form.submit(); //uncomment if we are not testing timeout

        let self = this;
        iframe.onload = function(){
            if(typeof self.tdsManager.loaded == 'function') {
                self.tdsManager.loaded();
            }

            if(self.tdsManager.iFrameMode == "subFrame"){
                var msg = {
                    mwTDSResult: "TDSPayframeSubFrameAction",
                    requiredAction : "loaded"
                    } 
                    var jsnMsg = JSON.stringify(msg);
                self.tdsManager.sendMessage(jsnMsg)
            }
        }

        this.tdsManager._handleChallengeCallback = function(event){self.tdsManager.handleChallengeCallback(event);}
        if(this.mwHandleNotifyURL){
            window?.addEventListener("message", this.tdsManager._handleChallengeCallback);

            this.challengeFlowFailureoverControl = setTimeout(()=>{ 
                this.challengeFlowFailure = true;
                this.tdsManager.returnMWCallBack({
                    "responseCode" : "-4",
                    "responseMessage" : "3DSv2 Challenge Flow Timeout",
                });
            }, 600000);
        }


        return true;
    }

    public createThreeDSMethodIframe(requestDetail:any): boolean {
        const displayBox = document.getElementById(this.tdsManager.containerDivId); 
        let iframe = document.createElement('iframe'); 
        // iframe.src = "about:blank";
        iframe.classList.add('hidden_TDS_From'); 
        iframe.style.display = "none"
        iframe.name = "threeDSMethodIframe";
        displayBox!.appendChild(iframe);

        var form = document.createElement("form");
        form.classList.add('hidden_TDS_From');
        form.id = "threeDSMethodForm";
        form.type = "hidden";
        displayBox!.appendChild(form);

        var input = document.createElement("input");
        input.id = "threeDSMethodData";
        input.name = "threeDSMethodData";
        input.type = "hidden";
        input.value = requestDetail.data.paReq;
        form.appendChild(input);

        form.action = requestDetail.data.acsURL;
        form.target = iframe.name;
        form.method = 'post'; 
        form.submit();

        let self = this;
        this.tdsManager._handleThreeDSMethodCallback = function(event){
            self.tdsManager.handleThreeDSMethodCallback(event, "TDSv2");
        }

        if(this.mwHandleNotifyURL){
            window?.addEventListener("message", this.tdsManager._handleThreeDSMethodCallback);
            /// 3DSv2 Specification: If the callback to threeDSMethodNotificationURL is not received within 10 seconds from the POST call, it has failed. 
            //enable failureover
            this.threeDSMethodFailureoverControl = setTimeout(()=>{ 
                this.threeDSMethodFailure = true;
                this.checkAuth(requestDetail.data.paReq,"N").then((result:any) => {
                    this.tdsManager.handleCheckTDSAuthResult(result);
                });
            }, 10000);
            // console.log("Debug - threeDSMethodFailureoverControl fallback" + this.threeDSMethodFailureoverControl);


        }

        return true;
    }


    private startCheckEnrollment(): any {
        const processCardRequest: APIRequest = {
            method: 'checkEnrollment',
            paymentCardNumber: this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardNumber,
            paymentCardExpiry: this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardExpiry,
            paymentCardName: this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardName,
            customerName: this.threeDSPaymentInfo.customerName,
            transactionAmount:  this.threeDSPaymentInfo.transactionInfo.transactionAmount,
            transactionCurrency:  this.threeDSPaymentInfo.transactionInfo.transactionCurrency,
            transactionProduct: this.threeDSPaymentInfo.transactionInfo.transactionProduct,
            notifyURL:  this.threeDSNotifyURL,
            source: "WEB-SDK"
        }
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.cardID) processCardRequest.cardID = this.threeDSPaymentInfo.transactionInfo.cardInfo.cardID;
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.cardKey) processCardRequest.cardKey = this.threeDSPaymentInfo.transactionInfo.cardInfo.cardKey;
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardCSC) processCardRequest.paymentCardCSC = this.threeDSPaymentInfo.transactionInfo.cardInfo.paymentCardCSC;
        //payframe Token
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeToken) processCardRequest.payframeToken = this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeToken;
        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeKey) processCardRequest.payframeKey = this.threeDSPaymentInfo.transactionInfo.cardInfo.payframeKey;

        if(this.threeDSPaymentInfo.transactionInfo.cardInfo.digitalWalletToken) processCardRequest.digitalWalletToken = this.threeDSPaymentInfo.transactionInfo.cardInfo.digitalWalletToken;
        if(this.threeDSPaymentInfo.transactionInfo.tdsDCC) {
            processCardRequest.tdsDCC = this.threeDSPaymentInfo.transactionInfo.tdsDCC;
            processCardRequest.originalAmount = this.threeDSPaymentInfo.transactionInfo.originalAmount;
            processCardRequest.originalCurrency = this.threeDSPaymentInfo.transactionInfo.originalCurrency;
        }

        const response = this.tdsManager.makeXHRRequest(processCardRequest);

        return response;
    }


    public getMWHandleNotifyURL(){
        return this.mwHandleNotifyURL;
    }

    public setTDSCardToken(response:any){
        if(response.cardToken.tdsPayKey && response.cardToken.tdsPayToken){
            this.tdsCardToken.tdsPayKey = response.cardToken.tdsPayKey;
            this.tdsCardToken.tdsPayToken =response.cardToken.tdsPayToken;
        }
    }
    public getTDSCardToken(){
        return this.tdsCardToken;
    }

    private pad2(n:any) { 
        return n < 10 ? '0' + n : n 
    }

    public getthreeDSMethodFailureoverControlID(){
        return this.threeDSMethodFailureoverControl;
    }


}