import { PaymentSummary, MethodData, APIRequest } from '../Types';
import { MerchantWarriorController } from '../MerchantWarriorController';
import { DigitalWallet } from './DigitalWallet';

import GooglePayJS = google.payments.api;
import {Customer} from "../Customer";

export class GooglePayIframe implements DigitalWallet {
    private controller: MerchantWarriorController;
    private data: any;
    private summary: PaymentSummary;
    private supportedMethods: string;

    constructor(controller: MerchantWarriorController, summary: PaymentSummary, data: any) {
        this.supportedMethods = 'https://google.com/pay'; // For PR API
        this.data = data;
        this.summary = summary;
        this.controller = controller;
        this.data.transactionInfo = this.formatSummary(summary);

        this.processPayment();
    }

    public static create(controller: MerchantWarriorController, summary: PaymentSummary): GooglePayIframe {
        const data = {

        }

        const wallet : GooglePayIframe = new GooglePayIframe(controller, summary, data);
        return wallet;
    }

    private formatSummary(summary: PaymentSummary): GooglePayJS.TransactionInfo {
        const info = this.controller.getInfo();

        let totalPrice: string;
        if (typeof summary.total.amount == 'string') {
            totalPrice = summary.total.amount;
        } else {
            totalPrice = summary.total.amount.value;
        }

        const transactionInfo: GooglePayJS.TransactionInfo = {
            currencyCode: info.currency,
            totalPrice: totalPrice,
            totalPriceStatus: 'FINAL',
            totalPriceLabel: summary.total.label
        }

        return transactionInfo;
    }

    /**
     * Get the iframe
     * @param pay
     * @private
     */
    private getIframe(pay: boolean = true) : Promise<HTMLIFrameElement> {
        // Create Iframe
        const iframe: HTMLIFrameElement = document.createElement('iframe');

        const uuid : string =  this.controller.getMerchantUUID();
        const apiKey: string =  this.controller.getMerchantApiKey();
        const accessToken : string =  this.controller.getMerchantAccessToken();

        if(accessToken && accessToken.length > 0){
            iframe.src = process.env.MW_IFRAME_URL+'googlepay-iframe?token='+accessToken;
        }else{
            iframe.src = process.env.MW_IFRAME_URL+'googlepay-iframe?uuid='+uuid+'&key='+apiKey;
        }

        iframe.style.border = '0px';
        iframe.style.padding = '0';
        iframe.style.margin = '0px';

        const iframeLoaded : Promise<unknown> = new Promise((resolve, reject) => {
            iframe.onload = resolve;
            iframe.onerror = reject;
        });
        iframeLoaded.then( () => {

            const iframeData = {
                data: this.summary,
                styles: {
                    height: this.summary?.googlePay?.buttonHeight,
                    width: this.summary?.googlePay?.buttonWidth,
                }
            }

            // pass styles from summary
            iframe.style.height = this.summary?.googlePay?.buttonHeight ? this.summary.googlePay.buttonHeight : '';
            iframe.style.width = this.summary?.googlePay?.buttonWidth ? this.summary.googlePay.buttonWidth : '100%';

            iframe.style.marginTop = this.summary?.googlePay?.marginTop ? this.summary.googlePay.marginTop : '';
            iframe.style.marginRight = this.summary?.googlePay?.marginRight ? this.summary.googlePay.marginRight : '';
            iframe.style.marginBottom = this.summary?.googlePay?.marginBottom ? this.summary.googlePay.marginBottom : '';
            iframe.style.marginLeft = this.summary?.googlePay?.marginLeft ? this.summary.googlePay.marginLeft : '';

            this.passPostMessage(iframe, iframeData);
        }).catch(error => {
            return Promise.reject();
        });

        return Promise.resolve(iframe);
    }

    /**
     * Send the message to the iframe window
     * @param iframe
     * @param iframeData
     * @private
     */
    private passPostMessage(iframe: HTMLIFrameElement, iframeData: object) {
        const message : string = JSON.stringify(iframeData);
        iframe.contentWindow?.postMessage(message, '*');
    }

    public getPayButton(): Promise<HTMLElement> {
        return this.getIframe();
    }

    public getAddButton(): Promise<HTMLElement> {
        return this.getIframe(false);
    }


    public getMethodData(): Promise<MethodData> {
        const methodData = {
            supportedMethods: this.supportedMethods,
            data: this.data
        };

        return Promise.resolve(methodData);
    }

    public processPayment() {
        window?.addEventListener('message',  (event) => {
            if(event.origin && process.env.MW_FUSE_URL){
                let originUrl : URL = new URL(event.origin);
                let fuseUrl : URL = new URL(process.env.MW_FUSE_URL);

                if (originUrl.host !== fuseUrl.host) {
                    return;
                }

                // This gets triggered with the Google controller emit function
                let result = JSON.parse(event.data);

                let status: boolean = false;
                if (result.responseCode == 0) {
                    status = true;
                }
                if(result.eventType == "payment-complete"){
                    this.controller.emit('payment-complete', status, result);
                }else{
                    this.controller.emit('card-added', status, result);
                }
                MerchantWarriorController.stopEventPropagation(event);
            }
            return;
        });

    }


}
