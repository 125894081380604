import { PaymentSummary, LineItem, MethodData, APIRequest } from '../Types';
import { Customer } from '../Customer';
import { MerchantWarriorController } from '../MerchantWarriorController';
import { DigitalWallet } from './DigitalWallet';
import { ApplePayIframe2 } from './ApplePayIframe2';

declare global {
    interface Window {
        ApplePaySession: ApplePaySession,
    }
}


const isApplePayShippingContactUpdate = (obj: any): obj is ApplePayJS.ApplePayShippingContactUpdate => {
    if(!obj)return false;
    const allowedProperties: Record<string, boolean> = {
        newTotal: true,
        newLineItems: true,
        newMultiTokenContexts: true,
        newAutomaticReloadPaymentRequest: true,
        newRecurringPaymentRequest: true,
        newDeferredPaymentRequest: true,
        errors: true,
        newShippingMethods: true,
    };
    const keys = Object.keys(obj);
    for (const key of keys) {
        if (!allowedProperties[key]) {
            return false;
        }
    }
    return true;
};

export class ApplePay implements DigitalWallet {
    private controller: MerchantWarriorController;
    private data: any;
    private summary: PaymentSummary;
    private supportedMethods: string;
    private buttonElement: boolean = false;

    constructor(controller: MerchantWarriorController, summary: PaymentSummary) {
        this.supportedMethods = 'https://apple.com/apple-pay'; // For PR API

        let allowedCardNetworks: string[] = this.getAllowedCardNetworks(summary);

        this.data = {
            version: 3,
            merchantIdentifier: process.env.MW_APPLE_MERCHANT_IDENTIFIER ?? '',
            merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
            supportedNetworks: allowedCardNetworks,
            countryCode: controller.getInfo().country
        };
        this.controller = controller;
        this.summary = summary;

        if(this.summary.applePay?.buttonElement)this.buttonElement = true;
    }

    public static create(controller: MerchantWarriorController, summary: PaymentSummary): Promise<ApplePay | ApplePayIframe2> {
        //Check the Iframe Level
        if (window.self !== window.top) {
            //Attempt to communicate with Top Parent
            var msg = {
                'message':'mw_applepay_request',
                'action':'initiate',
                "accessToken": controller.getMerchantAccessToken(),
                "summary": summary,
                "options": controller.getOptions(),
                "merchantInfo": controller.getInfo(),
                "merchantUUID": controller.getMerchantUUID(),
                "apiKey": controller.getMerchantApiKey(),
            };
            window.parent.postMessage(JSON.stringify(msg), "*");

            return new Promise(function(resolve, reject) {
                function ApplePayIframeMessageEventHandler(event: MessageEvent) {
                    let result;
                    try {
                        result = JSON.parse(event.data);
                    } catch (e) {
                        return;
                    }
                    if (result.message == "mw_applepay_response" && result.action == "acceptedApplepay") {
                        let promiseApple2 = ApplePayIframe2.create(controller, summary);
                        resolve(promiseApple2);
                        MerchantWarriorController.stopEventPropagation(event);
                        window.removeEventListener("message", ApplePayIframeMessageEventHandler);
                    } else {
                        window.removeEventListener("message", ApplePayIframeMessageEventHandler);
                        return reject(); // Applepay cannot be generated, e.g., duplicated object or Applepay isn't compatible
                    }
                }
                window.addEventListener("message", ApplePayIframeMessageEventHandler);
            });

        }else{
            

            if (!window.ApplePaySession && (summary.applePay?.crossPlatform !== false)) {
                    return new Promise<typeof ApplePaySession> ((resolve, reject)=> {
                      // Check if the SDK is already loaded
                      if (typeof ApplePaySession !== 'undefined') {
                        resolve(ApplePaySession);
                         return;
                      }
                      // Create a script element
                      const script = document.createElement('script');
                      script.src = 'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js';
                      script.crossOrigin = 'anonymous';
                  
                      // Attach event listeners
                      script.onload = () => {
                        if (typeof ApplePaySession !== 'undefined') {
                          resolve(ApplePaySession);
                        } else {
                          reject(new Error('ApplePaySession failed to load'));
                        }
                      };
                      script.onerror = () => {
                        reject(new Error('Failed to load the Apple Pay SDK'));
                      };
                  
                      // Append the script to the document
                      document.head.appendChild(script);
                    }).then((ApplePaySession): Promise<ApplePay | ApplePayIframe2> => {
                    if (ApplePaySession && ApplePaySession.canMakePayments()) {
                        summary.applePay = summary.applePay ?? {};
                        summary.applePay.buttonElement = true;
                        const wallet = new ApplePay(controller, summary);
                        return Promise.resolve(wallet);
                    } else {
                        //Apple Pay is not supported on this device/browser
                        return Promise.reject();
                    }
                  });

                // return Promise.reject();
            }else{
                if (!window.ApplePaySession) {
                    return Promise.reject();
                }

                if (!ApplePaySession.canMakePayments()) {
                    return Promise.reject();
                }
        
                const wallet = new ApplePay(controller, summary);
                return Promise.resolve(wallet);
            }

        }

    }


    public getPayButton(): Promise<HTMLElement> {
        const classes: string[] = [];
        const children: HTMLElement[] = [];
        classes.push('apple-pay-button-with-text');

        if (this.summary.style == 'light') {
            classes.push('apple-pay-button-white-with-text');
        } else { // Default dark if not set
            classes.push('apple-pay-button-black-with-text');
        }

        switch (this.summary.applePay?.buttonText) {
            case "buy":
                classes.push('apple-pay-button-buy');
                break;
            case "donate":
                classes.push('apple-pay-button-donate');
                break;
            case "plain":
                classes.push('apple-pay-button-plain');
                break;
            case "set-up":
                classes.push('apple-pay-button-set-up');
                break;
            case "book":
                classes.push('apple-pay-button-book');
                break;
            case "check-out":
                classes.push('apple-pay-button-check-out');
                break;
            case "subscribe":
                classes.push('apple-pay-button-subscribe');
                break;
            case "add-money":
                classes.push('apple-pay-button-add-money');
                break;
            case "contribute":
                classes.push('apple-pay-button-contribute');
                break;
            case "order":
                classes.push('apple-pay-button-order');
                break;
            case "reload":
                classes.push('apple-pay-button-reload');
                break;
            case "rent":
                classes.push('apple-pay-button-rent');
                break;
            case "support":
                classes.push('apple-pay-button-support');
                break;
            case "tip":
                classes.push('apple-pay-button-tip');
                break;
            case "top-up":
                classes.push('apple-pay-button-top-up');
                break;
            case "continue":
                classes.push('apple-pay-button-continue');
                break;
            default:
                classes.push('apple-pay-button-buy');
                break;
        }

        const text: HTMLElement = document.createElement('span');
        children.push(text);

        const logo: HTMLElement = document.createElement('span');
        children.push(logo);
        return this.getButton(classes, children).then((button: HTMLElement) => {
            button.onclick = () => { 
                this.startPayment();
            };

            return button;
        });
    }

    public getAddButton(): Promise<HTMLElement> {
        const classes: string[] = [];
        const children: HTMLElement[] = [];
        classes.push('apple-pay-button');

        if (this.summary.style == 'light') {
            classes.push('apple-pay-button-white');
        } else { // Default dark if not set
            classes.push('apple-pay-button-black');
        }

        return this.getButton(classes, children).then((button: HTMLElement) => {
            button.onclick = () => { 
                this.startAddCard();
            };

            return button;
        });
    }

    private getButton(classes: string[], children: HTMLElement[] = []): Promise<HTMLElement> {
        var button: HTMLElement;
        if(this.summary?.applePay && this.summary.applePay.buttonElement){
            button = document.createElement('apple-pay-button');
            //make legacy style compatible
            if(this.summary.style == 'light'){
                button.setAttribute('buttonstyle', "white");
            }else{
               button.setAttribute('buttonstyle', "black");
            }
            if(this.summary.applePay?.buttonText)button.setAttribute('type', this.summary.applePay?.buttonText);
            if(this.summary.applePay?.buttonstyle)button.setAttribute('buttonstyle', this.summary.applePay?.buttonstyle);
            if(this.summary.applePay?.locale)button.setAttribute('locale', this.summary.applePay?.locale);


        }else{
            button = document.createElement('div');
        }

        button.style.width = '100%'; // Why doesn't this work from CSS directly? Beats me
        button.style.height = '45px';
        const margin: string = '5px';

        for (let i = 0; i < classes.length; i++) {
            const cssClass = classes[i];
            button.classList.add(cssClass);
        }

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            button.appendChild(child);
        }
        //Integrate with ApplepayIframe introduced Style Feature 
        if(this.summary?.applePay && !(this.summary?.applePay.builtFrom == 'iframe')){
            if(this.summary.applePay.buttonHeight) button.style.height = this.summary.applePay.buttonHeight;
            if(this.summary.applePay.buttonWidth) button.style.width = this.summary.applePay.buttonWidth;
            if(this.summary.applePay.marginTop) button.style.marginTop = this.summary.applePay.marginTop;
            if(this.summary.applePay.marginRight) button.style.marginRight = this.summary.applePay.marginRight;
            if(this.summary.applePay.marginBottom) button.style.marginBottom = this.summary.applePay.marginBottom;
            if(this.summary.applePay.marginLeft) button.style.marginLeft = this.summary.applePay.marginLeft;
        }




        return Promise.resolve(button);
    }

    public startPayment(): void {
        let paymentSession: ApplePaySession | null = null;
        new Promise<Customer>((resolve: any, reject: any) => {
            if (this.summary.customer) {
                resolve(this.summary.customer);
                return;
            }
            this.controller.emit('request-customer', resolve, reject); // Prompt MerchantWarrior object for customer
        }).then((customer: Customer) => {
            this.summary.customer = customer;
            return this.createApplePayRequest();
        }).then((request: ApplePayJS.ApplePayPaymentRequest) => {
            return this.createApplePaySession(request);
        }).then((response: any) => {
            const status = response.responseCode == '0';
            this.controller.emit('payment-complete', status, response);
        }).catch((error: any) => {
            this.controller.emit('payment-complete', false, null, error);
        });
    }

    public startAddCard(): void {
        let paymentSession: ApplePaySession | null = null;
        this.createApplePayRequest().then((request: ApplePayJS.ApplePayPaymentRequest) => {
            return this.createApplePaySession(request, false);
        }).then((response: any) => {
            const status = response.responseCode == '0';
            this.controller.emit('card-added', status, response);
        }).catch((error: any) => {
            this.controller.emit('card-added', false, null, error);
        });
    }

    private createApplePayRequest(): Promise<ApplePayJS.ApplePayPaymentRequest> {
        // Convert PaymentRequest display items to ApplePay line items
        const displayItems: LineItem[] = this.summary.items ?? [];
        const lineItems: ApplePayJS.ApplePayLineItem[] = [];
        for (let i = 0; i < displayItems.length; i++) {
            const displayItem: LineItem = displayItems[i];
            let price: string;
            if (typeof displayItem.amount == 'string') {
                price = displayItem.amount;
            } else {
                price = displayItem.amount.value;
            }
            

            const lineItem: ApplePayJS.ApplePayLineItem = {
                label: displayItem.label,
                amount: price,
                type: 'final'
            }

            //MARK - Potentially we could set-up recurring for each item?

            lineItems.push(lineItem);
        }

        let totalPrice: string;
        if (typeof this.summary.total.amount == 'string') {
            totalPrice = this.summary.total.amount;
        } else {
            totalPrice = this.summary.total.amount.value;
        }

        const total: ApplePayJS.ApplePayLineItem = {
            label: this.summary.total.label,
            amount: totalPrice,
            type: 'final'
        }

        const info = this.controller.getInfo();
        let allowedCardNetworks: string[] = this.getAllowedCardNetworks(this.summary);

        const request: ApplePayJS.ApplePayPaymentRequest = {
            countryCode: info.country,
            currencyCode: info.currency,
            supportedNetworks: allowedCardNetworks,
            merchantCapabilities: [<ApplePayJS.ApplePayMerchantCapability> 'supports3DS'],
            total: total,
            lineItems: lineItems,
        };


        if (this.summary.recurring && this.summary.recurring.managementURL && this.summary.recurring.recurringPaymentStartDate
            && this.summary.recurring.recurringPaymentEndDate && this.summary.recurring.recurringPaymentIntervalUnit
            && this.summary.recurring.recurringPaymentIntervalCount) {
        
            const lineItemsForRecurring: ApplePayJS.ApplePayLineItem = {
                label: this.summary.total.label,
                amount: totalPrice,
                type: 'final',
                paymentTiming: "recurring",
                recurringPaymentStartDate : this.summary.recurring.recurringPaymentStartDate,
                recurringPaymentEndDate : this.summary.recurring.recurringPaymentEndDate,
                recurringPaymentIntervalUnit: this.summary.recurring.recurringPaymentIntervalUnit,
                recurringPaymentIntervalCount: this.summary.recurring.recurringPaymentIntervalCount,
            }

            const recurringPaymentRequest: ApplePayJS.ApplePayRecurringPaymentRequest = {
                paymentDescription: this.summary.total.label,
                regularBilling: lineItemsForRecurring,
                managementURL: this.summary.recurring.managementURL,
            };

            request.recurringPaymentRequest = recurringPaymentRequest;
        }
        
        if(this.summary.applePay?.requiredBillingContactFields)request.requiredBillingContactFields = this.summary.applePay?.requiredBillingContactFields;
        if(this.summary.applePay?.requiredShippingContactFields)request.requiredShippingContactFields = this.summary.applePay?.requiredShippingContactFields;
        if(this.summary.applePay?.shippingMethods)request.shippingMethods = this.summary.applePay?.shippingMethods;
        if(this.summary.applePay?.shippingType)request.shippingType = this.summary.applePay?.shippingType;
        if(this.summary.applePay?.supportedCountries)request.supportedCountries = this.summary.applePay?.supportedCountries;

        return Promise.resolve(request);
    }

    private createApplePaySession(request: ApplePayJS.ApplePayPaymentRequest, pay: boolean = true): Promise<any> {
        const session = new ApplePaySession(1, request);

        

        session.onvalidatemerchant = (event: ApplePayJS.ApplePayValidateMerchantEvent) => {
            this.validateMerchantSession(event.validationURL).then((merchantSession: any) => {
                session.completeMerchantValidation(merchantSession);
            });
        };

        return new Promise((resolve, reject) => {

            let transactionAmount = request.total.amount;
            let transactionCurrency = request.currencyCode;
            let transactionProduct = request.total.label;

            var onshippingcontactselectedFunction = this.summary.applePay?.onshippingcontactselected;
            if (typeof onshippingcontactselectedFunction === 'function') {
                session.onshippingcontactselected = (event) => {
                        var applePayShippingContactUpdateCandidate = onshippingcontactselectedFunction(event);
                        if(isApplePayShippingContactUpdate(applePayShippingContactUpdateCandidate)){
                            transactionAmount = applePayShippingContactUpdateCandidate.newTotal.amount || request.total.amount;
                            transactionProduct = applePayShippingContactUpdateCandidate.newTotal.label || request.total.label;

                            session.completeShippingContactSelection(applePayShippingContactUpdateCandidate)
                        }else{
                            session.completeShippingContactSelection({newTotal: {label: request.total.label, amount: request.total.amount}})
                        }
                }
            }

            var onshippingmethodselectedfunction = this.summary.applePay?.onshippingmethodselected;
            if (typeof onshippingmethodselectedfunction === 'function') {
                session.onshippingmethodselected = (event : ApplePayJS.ApplePayShippingMethodSelectedEvent) => {
                        var applePayShippingContactUpdateCandidate = onshippingmethodselectedfunction(event);
                        if(isApplePayShippingContactUpdate(applePayShippingContactUpdateCandidate)){
                            transactionAmount = applePayShippingContactUpdateCandidate.newTotal.amount || request.total.amount;
                            transactionProduct = applePayShippingContactUpdateCandidate.newTotal.label || request.total.label;

                            session.completeShippingMethodSelection(applePayShippingContactUpdateCandidate)
                        }else{
                            session.completeShippingMethodSelection({newTotal: {label: request.total.label, amount: request.total.amount}})
                        }
                    }
            }

            if(typeof onshippingmethodselectedfunction == undefined && typeof onshippingcontactselectedFunction == undefined){
                session.completeShippingContactSelection({newTotal: {label: request.total.label, amount: request.total.amount}})
                session.completeShippingMethodSelection({newTotal: {label: request.total.label, amount: request.total.amount}})

            }

            session.onpaymentauthorized = (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {

                this.controller.emit('payment-authorized', event.payment);

                var onSessionAuthorizedFunction = this.summary.applePay?.onSessionAuthorized;
                if (typeof onSessionAuthorizedFunction === 'function') {
                    var applePaySessionAuthorizedFlag = onSessionAuthorizedFunction(event.payment);
                    if(applePaySessionAuthorizedFlag === "FAILURE"){
                        const result: ApplePayJS.ApplePayPaymentAuthorizationResult = {
                            status: ApplePaySession.STATUS_FAILURE,
                        }
                        session.completePayment(result);
                        reject();
                        return;
                    }else if(applePaySessionAuthorizedFlag === "SKIP_AUTH_APPROVE"){
                        const result: ApplePayJS.ApplePayPaymentAuthorizationResult = {
                            status: ApplePaySession.STATUS_SUCCESS,
                        }
                        session.completePayment(result);
                        reject();
                        return;
                    }
                }

                let processRequest: APIRequest;
                if (pay) {

                    processRequest = {
                        method: 'processCard',
                        transactionAmount: transactionAmount,
                        transactionCurrency: transactionCurrency,
                        transactionProduct: transactionProduct,
                        digitalWalletToken: JSON.stringify(event.payment.token.paymentData)
                    }

                    if(this.summary.transaction){
                        if(this.summary.transaction.transactionReferenceID) processRequest.transactionReferenceID = this.summary.transaction.transactionReferenceID;
                        if(this.summary.transaction.storeID) processRequest.storeID = this.summary.transaction.storeID;
                        if(this.summary.transaction.custom1) processRequest.custom1 = this.summary.transaction.custom1;
                        if(this.summary.transaction.custom2) processRequest.custom2 = this.summary.transaction.custom2;
                        if(this.summary.transaction.custom3) processRequest.custom3 = this.summary.transaction.custom3;
                        if(this.summary.transaction.transactionProduct) processRequest.transactionProduct = this.summary.transaction.transactionProduct;
                    }

                    if (this.summary.addCard) {
                        processRequest.addCard = 1;
                    }

                } else {
                    processRequest = {
                        method: 'addCard',
                        digitalWalletToken: JSON.stringify(event.payment.token.paymentData),
                    }
                }
                
                if(event.payment.token.paymentMethod){
                    processRequest.walletDisplayName = event.payment.token.paymentMethod.displayName;
                    processRequest.walletNetwork = event.payment.token.paymentMethod.network;
                    processRequest.walletType = event.payment.token.paymentMethod.type;

                    if(this.controller.getOptions().environment.toLowerCase() == "development" || this.controller.getOptions().environment.toLowerCase() == "test")
                        processRequest.walletRealCardTestName = "Apple Pay Payment Card";
                }



                let custoemrInfo = this.summary.customer;
                if(this.summary.applePay?.infoUpdate){
                    let infoUpdatePreference = this.summary.applePay?.infoUpdatePreference || 'billingAddress';
                    if(infoUpdatePreference == 'billingAddress' && event.payment.billingContact){
                        custoemrInfo = updateCustomerInfo(event.payment.billingContact, this.summary.customer);
                    }
                    if(infoUpdatePreference == 'shippingAddress' && event.payment.shippingContact){
                        custoemrInfo = updateCustomerInfo(event.payment.shippingContact, this.summary.customer);
                    }
                }

                let apiRequest = this.controller.makeRequest(processRequest, custoemrInfo);
                // By setting request to the then(), it ensures this will happen first
                apiRequest = apiRequest.then((response) => {
                    const result: ApplePayJS.ApplePayPaymentAuthorizationResult = {
                        status: ApplePaySession.STATUS_FAILURE
                    }

                    if (response.responseCode == '0') {
                        result.status = ApplePaySession.STATUS_SUCCESS;
                    }

                    session.completePayment(result);
                    return response;
                });
                resolve(apiRequest);
            }

            session.begin();
        });
    }

    private validateMerchantSession(validationURL: string): Promise<any> {
        const getMerchantSessionRequest = {
            validationURL: validationURL,
            domainName: location.hostname,
            method: 'getMerchantSession',
            source: "WEB-SDK",
        }
        return this.controller.makeRequest(getMerchantSessionRequest).then((response: any) => {
            const merchantSession = JSON.parse(response.merchantSession);
            return merchantSession; 
        });
    }

    public getMethodData(): Promise<MethodData> {
        const methodData = {
            supportedMethods: this.supportedMethods,
            data: this.data
        };

        return Promise.resolve(methodData);
    }

    private getAllowedCardNetworks(summary: PaymentSummary){

        let allowedCardNetworks: string[];
        if (summary.applePay?.allowedCardNetworks) {
            allowedCardNetworks = summary.applePay?.allowedCardNetworks.map((str) => str.toLowerCase());
        } else {
            allowedCardNetworks = ["visa", "mastercard"];
        }
        return allowedCardNetworks;
    }


}


const updateCustomerInfo = (appleContact: ApplePayJS.ApplePayPaymentContact, originalCustomer : Customer | undefined) : Customer => {
    let updatedCustomer: Customer;
    let appleContactName = (appleContact.givenName || '') + ((appleContact.givenName && appleContact.familyName) ? " ":"") + (appleContact.familyName || '');
    let appleContactAddressLine = appleContact.addressLines?.join(" ");
    let appleContactEmail = appleContact.emailAddress;
    if(originalCustomer){
        updatedCustomer= {
            name: appleContactName || originalCustomer.name,
            country: appleContact.countryCode || originalCustomer.country,
            state: appleContact.administrativeArea || originalCustomer.state,
            city: appleContact.locality || originalCustomer.city,
            address: appleContactAddressLine || originalCustomer.address,
            postCode: appleContact.postalCode || originalCustomer.postCode,
            phone: appleContact.phoneNumber || originalCustomer.phone,
            email: appleContactEmail || originalCustomer.email,
            ip: originalCustomer.ip,
        };
    }else{
       updatedCustomer= {
            name: appleContactName || '',
            country: appleContact.countryCode || '',
            state: appleContact.administrativeArea || '' ,
            city: appleContact.locality || '' ,
            address:  appleContactAddressLine || "",
            postCode: appleContact.postalCode || '' ,
            email: appleContactEmail,
            phone: appleContact.phoneNumber,
        };
    }
    return updatedCustomer;
}